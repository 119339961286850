import { setLS, getLS, clearLS } from "@/utils/storage";
import { encrypt } from "@/utils";
import { isEtbcTenant } from "@/utils";
// eslint-disable-next-line no-unused-vars
import { apiGetTenantDetails } from "@/api/login";
import { isBlank } from "@/utils/validate";
import { apiGetWaterRegionTree } from "@/api/regionManage";
export default {
  state: {
    token: getLS("token"),
    userInfo: getLS("userInfo") || {}, // 用户信息
    permissions: getLS("permission") || [], // 权限列表
    isolationOrgId: getLS("isolationOrgId"), // 租户下数据隔离级别组织id
    isolationOrgCode: getLS("isolationOrgCode"), // 租户下数据隔离级别组织编码
    isolationOrgName: getLS("isolationOrgName"), // 租户下数据隔离级别组织名称
    showSwitchOrgDialogGlobal: false, // 组织机构选择弹窗的开关
    switchTenantModalVisible: false,
    selectTenantId: "",
    selectTenantName: "",
    userRegionTree: [], //用户权限区域树
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      setLS("token", token);
      state.token = token;
    },
    SET_USER_INFO: (state, userInfo) => {
      setLS("userInfo", userInfo);
      state.userInfo = userInfo;
    },
    SET_PERMISSIONS: (state, permissions) => {
      setLS("permission", permissions);
      state.permissions = permissions;
    },
    SET_ISOLATION_ORG: (state, { id, orgCode, name }) => {
      setLS("isolationOrgId", id);
      setLS("isolationOrgCode", orgCode);
      setLS("isolationOrgName", name);
      state.isolationOrgId = id;
      state.isolationOrgCode = orgCode;
      state.isolationOrgName = name;
    },
    CHANGE_SHOW_SWITCH_ORG_DIALOG_GLOBAL(state, payload) {
      state.showSwitchOrgDialogGlobal = payload;
    },

    UPDATE_SWITCH_TENANT_MODAL_VISIBLE(state, payload) {
      state.switchTenantModalVisible = payload;
    },

    UPDATE_SELECT_TENANT_INFO(state, { tenantId, tenantName }) {
      state.selectTenantId = tenantId;
      state.selectTenantName = tenantName;
    },
    SET_USER_REGION_TREE(state, tree) {
      state.userRegionTree = tree;
    },
  },
  actions: {
    getTreeOptions({ state, commit, rootGetters }, updateFlag = false) {
      return new Promise((resolve, reject) => {
        // 燃气租户不请求水务租户的区域树
        const { tenantType, staffId } = state.userInfo;
        if (tenantType !== "1") {
          return resolve([]);
        }
        apiGetWaterRegionTree({
          staffId,
        })
          .then((data) => {
            commit("SET_USER_REGION_TREE", data);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    login({ state, commit }, { params, apiFn }) {
      return new Promise((resolve, reject) => {
        apiFn({
          ...params,
          password: params?.password ? encrypt(params.password) : undefined,
        })
          .then(async (res) => {
            const { pwStrength, accessToken } = res;
            const token = state.token;
            if (isEtbcTenant && token && token != accessToken) {
              clearLS();
              setTimeout(() => {
                location.reload();
              }, 100);
              return;
            }
            if (!pwStrength || pwStrength == 1) {
              const {
                accessToken,
                permissions = [],
                functionalCodes,
                waterRegionCode,
                ...userInfo
              } = res;
              const permissionCodes =
                functionalCodes ||
                permissions.map((item) => item.permissionCode);
              commit("SET_TOKEN", accessToken);
              commit("SET_PERMISSIONS", permissionCodes);
              let defaultWaterRegionCode = undefined;
              if (!isBlank(waterRegionCode)) {
                const codes = waterRegionCode.split(",");
                let minLenCode = codes[0];
                for (let i = 1; i < codes.length; i++) {
                  if (minLenCode.length > codes[i].length) {
                    minLenCode = codes[i];
                  }
                }
                defaultWaterRegionCode = minLenCode;
              }

              commit("SET_USER_INFO", {
                ...userInfo,
                waterRegionCode,
                tenantType: isBlank(userInfo.tenantType)
                  ? "0"
                  : userInfo.tenantType,
                defaultWaterRegionCode,
              });
              resolve({
                flag: true,
                result: res,
              });
            } else {
              resolve({
                flag: false,
                result: res,
              });
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    judgeNeedPickOrg({ state, commit }) {
      return new Promise((resolve) => {
        if (
          state.userInfo?.needPeekIsolation &&
          (!state.isolationOrgId || !state.isolationOrgCode)
        ) {
          commit("CHANGE_SHOW_SWITCH_ORG_DIALOG_GLOBAL", true);
          resolve(false);
        } else {
          resolve(true);
        }
      });
    },
  },
};
