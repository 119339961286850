<template>
  <gc-dialog
    :show.sync="visible"
    :title="title"
    :ok-text="okText"
    :show-footer="status !== 2"
    @ok="handleOk"
    @cancel="visible = false"
    @close="visible = false"
  >
    <el-form
      v-if="status === 1"
      ref="form"
      :model="form"
      :rules="formRules"
      label-position="top"
    >
      <el-form-item label="设备类型名称" prop="deviceTypeId">
        <el-select v-model="form.deviceTypeId" filterable placeholder="请选择">
          <el-option
            v-for="item in deviceTypeList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="所属区域"
        prop="waterRegionCode"
        v-if="tenantType == 1 && form.optType != 0"
      >
        <gc-tree-select
          v-model="form.waterRegionCode"
          placeholder="请选择所属区域"
          :props="{
            label: 'name',
            id: 'code',
            disabled: (data) => {
              return data.unSelectFlag && data.unSelectFlag === 1;
            },
          }"
          :options="treeOptions"
        ></gc-tree-select>
      </el-form-item>
      <el-form-item label="操作类型" prop="optType">
        <el-radio-group v-model="form.optType" @change="handleRadioChange">
          <el-radio :label="0">仅新增设备</el-radio>
          <el-radio :label="1">新增并安装设备</el-radio>
          <el-radio :label="2">仅安装设备</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="form.deviceStatus && type !== 'download'"
        label="安装地址"
        prop="areaCode"
        :rules="[
          {
            validator: adressCodeRule,
            trigger: '',
          },
        ]"
      >
        <el-cascader
          :options="locationOptions"
          v-model="form.areaCode"
          placeholder="请选择"
          popper-class="gc-customer-cascader"
        ></el-cascader>
      </el-form-item>
    </el-form>

    <div v-if="status === 2" class="box-upload">
      <el-upload
        class="btn"
        ref="upload"
        action
        drag
        :auto-upload="false"
        :show-file-list="false"
        :on-change="handleFileChange"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
  </gc-dialog>
</template>

<script>
import { ruleRequired } from "@/utils/rules";
import { mapActions } from "vuex";
import {
  apiEditDeviceView,
  apiStaffsCity,
  apiGetDistrictList,
} from "@/api/account";
import { export_json_to_excel as Export2Excel } from "@/utils/excel/Export2Excel";
import { batchImportThead } from "@/config/const";
import { mapMutations, mapGetters } from "vuex";
const XLSX = require("xlsx");

export default {
  name: "DataTemplateDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "download",
    },
    okText: {
      type: String,
      default: "下 载",
    },
  },
  data() {
    const adressCodeRule = (rule, value, callback) => {
      if (value && !value[2]) {
        return callback(new Error("请选择所在省市的区"));
      }
      callback();
    };
    return {
      form: {
        deviceTypeId: null,
        deviceTypeName: null,
        optType: 0,
        deviceStatus: 0,
        areaCode: "",
        waterRegionCode: null,
      },
      formRules: {
        optType: [ruleRequired("请选择操作类型")],
        deviceTypeId: [ruleRequired("请选择设备类型")],
        waterRegionCode: [ruleRequired("请选择所属区域")],
      },
      deviceTypeList: [],
      deviceView: null, // 设备类型关联信息
      status: 1,
      locationOptions: [],
      adressCodeRule,
    };
  },
  computed: {
    ...mapGetters({
      isolationOrgId: "isolationOrgId",
    }),
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
    title() {
      return this.type === "upload"
        ? this.status === 1
          ? "确认模板信息"
          : "上传文件"
        : "下载数据模板";
    },
    tenantId() {
      return this.$store.getters.userInfo.tenantId || null;
    },
    tenantType() {
      return this.$store.getters.userInfo.tenantType || "0";
    },
    treeOptions() {
      return this.$store.getters.userRegionTree;
    },
  },
  watch: {
    show(val) {
      if (!val) {
        this.status = 1;
        this.form = this.$options.data().form;
        this.$refs.form?.resetFields();
      } else {
        if (this.type !== "download") {
          this.getOrgOrTenantLocation();
        }
      }
    },
    isolationOrgId() {
      this.getDeviceTypeList();
    },
  },
  mounted() {
    this.getDeviceTypeList();
    if (this.tenantType == 1) {
      this.getTreeOptions();
    }
  },
  methods: {
    ...mapMutations({
      setDataTemplate: "account/SET_DATA_TEMPLATE",
      setDeviceView: "account/SET_DEVICE_VIEW",
      setExcelColumns: "account/SET_EXCEL_COLUMNS",
      setDeviceTotalAttrs: "account/SET_DEVICE_TOTAL_ATTRS",
      clearBatchImportData: "account/CLEAR_BATCH_IMPORT_DATA",
    }),
    ...mapActions({
      getDeviceTypes: "common/getDeviceTypeList",
      getProvinceList: "apiCache/getProvinceList",
      getTreeOptions: "user/getTreeOptions",
    }),

    getOrgOrTenantLocation() {
      apiStaffsCity({
        tenantId: this.tenantId,
      }).then((res) => {
        const additionalInfo = res.additionalInfo;
        this.additionalInfo = additionalInfo;
        apiGetDistrictList(additionalInfo.city).then((data) => {
          this.form.areaCode = [
            additionalInfo.province,
            additionalInfo.city,
            "",
          ];
          this.locationOptions = [
            {
              label: additionalInfo.provinceName,
              value: additionalInfo.province,
              children: [
                {
                  label: additionalInfo.cityName,
                  value: additionalInfo.city,
                  children: [
                    {
                      label: "",
                      value: "",
                    },
                    ...data.records.map((o) => {
                      return {
                        label: o.regionName,
                        value: o.regionCode,
                      };
                    }),
                  ],
                },
              ],
            },
          ];
        });
      });
    },

    getDeviceTypeList() {
      // 获取设备类型列表
      this.getDeviceTypes().then((data) => {
        this.deviceTypeList = data.filter((item) => item.isSupportBatchImport);
        // this.deviceTypeList = data.filter((item) =>
        //   // 只需要显示NB10表类型（金卡NB超声10、金卡热式R1、金卡热式R2、金卡电子温补、金卡NBIOT10、金卡NB膜式本安I-D10）和金卡报警器二代表类型（金卡NB家用报警器Ⅱ-S）
        //   [5, 7, 8, 9, 10, 57, 63].includes(+item.deviceTypeId)
        // );
      });
    },
    handleRadioChange(val) {
      this.form.deviceStatus = val ? 1 : 0;
      this.$refs.form.clearValidate("areaCode");
      if (this.tenantType == 1) {
        val === 0 ? (this.form.waterRegionCode = null) : null;
        this.$nextTick(() => {
          this.$refs.form.clearValidate("waterRegionCode");
        });
      }
    },

    handleAreaData(data = [], level) {
      const result = data.map((item) => {
        return {
          value: item.regionCode,
          label: item.regionName,
          leaf: level >= 2,
        };
      });
      return result;
    },
    handleOk() {
      let flag = false;
      this.$refs.form.validate((valid) => {
        flag = valid;
      });
      if (!flag) return;
      const { deviceTypeId } = this.form;
      if (!deviceTypeId) return;
      apiEditDeviceView({
        deviceTypeId,
        operate: 0,
      }).then((res) => {
        console.log("edit device: ", res);
        this.deviceView = res;
        this.handleExcelColumns();
      });
      if (this.type === "download") {
        this.$message.success("正在下载请稍后");
      }
    },
    // 处理当前模板设备属性、表头和示例信息
    handleExcelColumns() {
      let { baseProductAttributes, deviceTypeAttributes } = this.deviceView;
      baseProductAttributes = baseProductAttributes || [];
      deviceTypeAttributes = deviceTypeAttributes || [];
      const { baseAttrs, installAttrs } = batchImportThead(this.tenantType);
      const { optType } = this.form;
      // 仅安装设备时需要上传子设备数量和压力点数量
      const specialAttrs = baseProductAttributes.filter(
        (item) =>
          item.attributeKey === "subDeviceCount" ||
          item.attributeKey === "pressureCount"
      );
      let columns;
      let totalAttrs;
      switch (optType) {
        // 操作类型为仅新增设备
        case 0:
          columns = [...baseAttrs, ...baseProductAttributes];
          totalAttrs = [
            ...baseAttrs,
            ...baseProductAttributes,
            ...deviceTypeAttributes,
          ];
          break;
        // 操作类型为新增并安装设备
        case 1:
          columns = [...baseAttrs, ...baseProductAttributes, ...installAttrs];
          totalAttrs = [
            ...baseAttrs,
            ...baseProductAttributes,
            ...deviceTypeAttributes,
            ...installAttrs,
          ];
          break;
        // 操作类型为仅安装设备（只需填写设备编号和安装信息）
        case 2:
          columns = [...baseAttrs, ...specialAttrs, ...installAttrs];
          totalAttrs = [...baseAttrs, ...specialAttrs, ...installAttrs];
          break;
      }
      if (this.type === "upload") {
        const { deviceTypeName } = this.deviceTypeList.find(
          (item) => item.deviceTypeId === this.form.deviceTypeId
        );
        this.form.deviceTypeName = deviceTypeName;
        console.log(this.form, "form-------------------");
        this.setDataTemplate({ ...this.form });
        this.setDeviceView({ ...this.deviceView });
        this.setExcelColumns(columns);
        this.setDeviceTotalAttrs(totalAttrs);
        this.status = 2;
        return;
      }
      const excelCase = []; // 模板示例数据
      const excelTHead = columns.map((item) => {
        const date = this.dayjs().format("YYYY/MM/DD");
        const dateNumber = this.dayjs().format("YYYYMMDD");
        if (item.case) {
          excelCase.push(item.case);
        } else if (+item.valueType === 1) {
          // 日期
          excelCase.push(date);
        } else if (item.attributeKey === "deviceNo") {
          // 设备编号
          excelCase.push(`${dateNumber}0001`);
        } else if (item.attributeKey === "airInletDirection") {
          // 进气方向
          excelCase.push("左（或右）");
        } else if (item.attributeKey === "certificateValidityPeriod") {
          // 证书有效时间
          excelCase.push("1年");
        } else {
          excelCase.push("xxx");
        }
        return item.name;
      });
      const excelName = "表格数据模板";
      Export2Excel(excelTHead, [excelCase], excelName);
      this.visible = false;
    },
    // 上传文件后获取表格数据
    handleFileChange(file) {
      const { name, size, raw } = file;
      if (!/\.(xls|xlsx)$/.test(name.toLowerCase())) {
        this.$message.error("上传文件格式不正确，请上传xls或xlsx格式文档");
        return false;
      }
      if (size / 1024 / 1024 > 10) {
        this.$message.error("上传文件大小不得大于10M");
        return false;
      }
      const loading = this.$loading({ text: "正在读取，请等待…" });
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const sheetNames = workbook.SheetNames;
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[0]], {
            defval: null, // 表格数据未填写时填充默认值null
          }); // 第一张表生成json数据
          if (ws.length > 3000) {
            this.$message.error("上传数据不能超过3000条");
            loading.close();
            return;
          }
          const fileSize =
            size > 1024 * 1024
              ? (size / 1024 / 1024).toFixed(1) + "M"
              : size > 1024
              ? (size / 1024).toFixed(1) + "KB"
              : size + "B";
          setTimeout(() => {
            this.$emit("file-info", { fileName: name, fileSize, fileData: ws });
            loading.close();
            this.visible = false;
          }, 2000);
        } catch {
          loading.close();
          this.$message.error("excel读取发生错误，请重新读取");
        }
      };
      fileReader.readAsBinaryString(raw);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  height: 64px;
}
.box-upload {
  margin: 20px auto 50px;
  text-align: center;
}
</style>

<style lang="scss">
.gc-customer-cascader {
  .el-cascader-panel {
    .el-cascader-menu {
      &:nth-child(-n + 2) {
        display: none;
      }
      &:last-child {
        li {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}
</style>
