<template>
  <gc-dialog
    title="批量编辑"
    :show.sync="visible"
    :ok-text="'确 定'"
    v-loading="loading"
    @ok="handleOk"
    @cancel="visible = false"
    @close="visible = false"
    :append-to-body="true"
  >
    <el-form ref="form" :model="form" label-position="top">
      <el-form-item ref="installDate" prop="installDate" label="安装日期">
        <el-date-picker
          v-model="form.installDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item ref="userType" prop="userType" label="客户类型">
        <el-select v-model="form.userType" placeholder="请选择">
          <el-option v-if="false" :value="null">全部</el-option>
          <el-option
            v-for="option in options.userType"
            :key="option.defaultValue"
            :value="+option.defaultValue"
            :label="option.name"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item ref="homeType" prop="homeType" label="是否独居老人">
        <el-radio-group v-model="form.homeType">
          <el-radio
            v-for="option in options.homeType"
            :key="option.defaultValue"
            :label="Number(option.defaultValue)"
            >{{ option.name }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item
        ref="waterRegionCode"
        prop="waterRegionCode"
        label="所属区域"
      >
        <gc-tree-select
          v-model="form.waterRegionCode"
          placeholder="请选择所属区域"
          :props="{
            label: 'name',
            id: 'code',
            disabled: (data) => {
              return data.unSelectFlag && data.unSelectFlag === 1;
            },
          }"
          :options="treeOptions"
        ></gc-tree-select>
      </el-form-item>
    </el-form>
  </gc-dialog>
</template>

<script>
import { apiBatchEdit } from "@/api/account";
import { mapActions } from "vuex";
export default {
  name: "BatchEdit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    devices: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      form: {
        installDate: undefined,
        waterRegionCode: undefined,
        homeType: undefined,
      },
      showResult: false,
      loading: false,
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
    treeOptions() {
      return this.$store.getters.userRegionTree;
    },
    options() {
      const { homeType } = this.$store.getters.dataList;
      return {
        homeType: homeType || [],
      };
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    show(val) {
      if (!val) {
        this.form = this.$options.data().form;
        this.$refs.form?.resetFields();
      } else {
        this.getTreeOptions();
      }
    },
  },
  methods: {
    ...mapActions({
      getTreeOptions: "user/getTreeOptions",
    }),
    async handleOk() {
      const valid = await this.$refs.form.validate();
      console.log("valid: ", valid, this.form);
      if (!valid) return;
      const dataList = this.devices.map((item) => {
        const { deviceId, deviceNo, deviceStatus } = item;
        return {
          deviceId,
          deviceNo,
          deviceStatus,
          ...this.form,
        };
      });
      this.loading = true;
      apiBatchEdit({
        dataList,
        tenantType: this.userInfo.tenantType,
      })
        .then((data) => {
          if (data.length) {
            const successMsg = `<div class='msg-title'>成功${
              this.devices.length - data.length
            }条，失败${data.length}条</div>`;
            const failedList = data.map(
              (item) =>
                `<p>${item.deviceNo}，失败原因：${item.errorMessage}</p>`
            );
            this.$alert(
              successMsg +
                "<div class='msg-title'>以下设备修改失败：</div>" +
                failedList,
              "批量编辑",
              {
                dangerouslyUseHTMLString: true,
                callback: () => {
                  this.visible = false;
                  this.$emit("refresh");
                },
              }
            );
          } else {
            this.$message.success("编辑成功");
            this.visible = false;
            this.$emit("refresh");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-form {
    margin-top: 20px;
  }
}
</style>
