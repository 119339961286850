<template>
  <div class="gc-region-tree">
    <div class="tree-search" v-if="filterable">
      <gc-custom-search
        width="100%"
        placeholder="输入区域名称进行搜索"
        :search.sync="searchValue"
      ></gc-custom-search>
    </div>
    <div class="tree-content">
      <!-- <el-radio-group
        class="gc-region-tree-el-radio-group"
        v-model="value"
        @input="(val) => $emit('update:value', val)"
      > -->
      <el-tree
        ref="gloabRegionTree"
        :node-key="valueKey"
        :data="!lazy ? treeRegionOptions : null"
        :highlight-current="!showSelect"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        :indent="12"
        :props="propsObj"
        :show-checkbox="showSelect"
        :check-strictly="false"
        :default-expand-all="expandAll"
        :lazy="lazy"
        :load="loadNode"
        :default-expanded-keys="defaultExpandedKeys"
      >
        <!-- @check="handleEventCheck"
          @node-click="(node) => $emit('update:value', node[valueKey])" -->
        <div
          class="custom-tree-node"
          :class="{
            hasCheckbox: node.data.hasCheckBox && showSelect,
            noHighLight: showSelect,
            disabled: isDisabled(node),
          }"
          slot-scope="{ node }"
          @click.stop.prevent="handleNodeClick(node.data)"
        >
          <!-- <el-radio
              :label="node.data[valueKey]"
              :key="node.data[valueKey]"
              :disabled="isDisabled(node)"
            > -->
          <img
            :src="getRegionTreeIcon(node)"
            :class="{
              w_15: node.level > 1 && !node.data.deviceId,
              w_17: node.data.deviceId,
            }"
            alt=""
            class="icon"
          />

          <p>{{ node.label }}</p>
          <!-- </el-radio> -->
        </div>
      </el-tree>
      <!-- </el-radio-group> -->
    </div>
  </div>
</template>

<script>
import { isBlank } from "@/utils/validate";
import { mapActions } from "vuex";

export default {
  name: "GcRegionTree",
  model: {
    prop: "value",
    event: "update:value",
  },
  props: {
    value: [String, Number],
    showSelect: {
      type: Boolean,
      default: false,
    },
    initSelect: {
      type: Boolean,
      default: true,
    },
    valueKey: {
      type: String,
      default: "code",
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    defaultExpandedKeys: {
      type: Array,
      default: () => [],
    },
    expandAll: {
      type: Boolean,
      default: true,
    },
    loadNode: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    propsObj: {
      type: Object,
      default: () => {
        return {
          label: "name",
          disabled: (data, node) => {
            return data?.unSelectFlag === 1;
          },
        };
      },
    },
  },
  data() {
    return {
      searchValue: "",
      checkList: [],
    };
  },
  created() {
    if (!this.lazy) {
      this.findRegionTreeList();
    }
  },
  computed: {
    treeRegionOptions() {
      return this.$store.getters.userRegionTree;
    },
  },

  methods: {
    ...mapActions({
      getTreeOptions: "user/getTreeOptions",
    }),
    handleNodeClick(node) {
      if (!this.isDisabled({ data: node })) {
        this.$emit("update:value", node[this.valueKey]);
      }
    },
    isDisabled(node) {
      const { disabled } = this.propsObj;
      return typeof disabled === "function"
        ? disabled(node.data)
        : node.disabled;
    },
    async findRegionTreeList() {
      try {
        const data = await this.getTreeOptions();
        if (this.initSelect) {
          this.setHighLightNode();
        }
      } catch (error) {
        this.$emit("update:value", null);
      }
    },

    findTargetTreeItemById(id, list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i][this.valueKey] === id) {
          return list[i];
        }
        if (list[i].children?.length) {
          const t = this.findTargetTreeItemById(id, list[i].children);
          if (t) return t;
        }
      }
    },

    setHighLightNode() {
      const t = this.findTargetTreeItemById(
        this.value,
        this.treeRegionOptions || []
      );

      this.$nextTick(() => {
        if (t) {
          this.$refs.gloabRegionTree.setCurrentKey(t[this.valueKey]);
        } else {
          // 区域编码不能为空 但是当前选中的区域可能被删除了
          if (this.initSelect && this.treeRegionOptions.length) {
            this.$emit(
              "update:value",
              this.treeRegionOptions[0][this.valueKey]
            );
          } else {
            this.$refs.gloabRegionTree.setCurrentKey(null);
          }
        }
      });
    },

    handleEventCheck(data, checks) {
      const checkedNodes = checks.checkedNodes.filter(
        (o) => o.deviceNo && o.deviceId
      );
      if (checkedNodes.length > 5) {
        this.$nextTick(() => {
          this.$refs.gloabRegionTree.setChecked(data.code, false);
        });
        return this.$message.warning("最多只能选中5个设备");
      }
      this.$emit("check-change", checkedNodes);
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    getRegionTreeIcon(node) {
      if (node.data.deviceId) {
        const iconMap = {
          3: require("@/assets/images/water/jmb.svg"),
          4: require("@/assets/images/water/gsb.svg"),
          5: require("@/assets/images/water/khb.svg"),
          6: require("@/assets/images/water/llj.svg"),
        };
        return iconMap[node.data.userType];
      }
      if (node.level === 1) {
        return require("@/assets/images/water/gsjd.svg");
      } else {
        return require("@/assets/images/water/qtjd.svg");
      }
    },
  },

  watch: {
    value: {
      handler(val) {
        this.setHighLightNode();
      },
      immediate: true,
    },

    searchValue(val) {
      this.$refs.gloabRegionTree.filter(val);
    },

    treeRegionOptions(val) {
      if (!isBlank(this.value)) {
        this.setHighLightNode();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.gc-region-tree-el-radio-group {
  width: 100%;
  ::v-deep .el-radio {
    width: 100%;
    display: flex;
    align-items: center;
    .el-radio__label {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
}
.gc-region-tree {
  height: 100%;
  display: flex;
  flex-direction: column;
  .tree-content {
    flex: 1;
    height: 0;
    padding-top: 12px;
    ::v-deep .el-tree {
      height: 100%;
      overflow-y: scroll;
      .el-tree-node {
        &__content {
          height: 42px;
          border-radius: 4px;
          .el-icon-caret-right:not(.is-leaf) {
            color: #3f435e;
            font-size: 12px;
            font-weight: bold;
          }
          .custom-tree-node {
            display: flex;
            flex: 1;
            width: 0;
            align-items: center;
            color: #3f435e;
            line-height: 22px;
            height: 100%;
            &.disabled {
              opacity: 0.5;
              //pointer-events: none;
              cursor: not-allowed;
              .icon {
                cursor: not-allowed;
              }
              p {
                cursor: not-allowed;
              }
            }
            .icon {
              margin-right: 4px;
              &.w_15 {
                width: 15px;
              }
              &.w_17 {
                width: 17px;
              }
            }
            p {
              flex: 1;
              width: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          &:hover {
            background-color: #f4f5fb;
          }
        }
        &.is-current {
          & > .el-tree-node__content {
            .custom-tree-node:not(.noHighLight):not(.disabled) {
              color: #4d6bff;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>
