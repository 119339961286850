<template>
  <gc-dialog
    :show.sync="visible"
    :title="title"
    @ok="handleOk"
    @cancel="visible = false"
    @close="visible = false"
  >
    <el-form ref="form" :model="form" :rules="formRules" label-position="top">
      <el-form-item label="厂商名称" prop="manufacturerName">
        <el-input v-model="form.manufacturerName" placeholder="请填写" />
      </el-form-item>
      <el-form-item label="厂商地址" prop="manufacturerAddress">
        <el-input v-model="form.manufacturerAddress" placeholder="请填写" />
      </el-form-item>
      <el-form-item label="联系人" prop="contactName">
        <el-input v-model="form.contactName" placeholder="请填写" />
      </el-form-item>
      <el-form-item label="联系电话" prop="contactPhone">
        <el-input v-model="form.contactPhone" placeholder="请填写" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          class="textarea"
          type="textarea"
          v-model="form.remark"
          placeholder="请填写"
          resize="none"
        />
      </el-form-item>
    </el-form>
  </gc-dialog>
</template>

<script>
import { ruleRequired, ruleMaxLength, RULE_PHONE } from "@/utils/rules";
import { apiManufacturerAdd, apiManufacturerUpdate } from "@/api/factory";

export default {
  name: "FactoryDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        manufacturerName: "",
        manufacturerAddress: "",
        contactName: "",
        contactPhone: "",
        remark: "",
      },
      formRules: {
        manufacturerName: [ruleRequired("请填写厂商名称"), ruleMaxLength(32)],
        manufacturerAddress: [ruleMaxLength(128)],
        contactName: [ruleMaxLength(32)],
        contactPhone: [RULE_PHONE],
        remark: [ruleMaxLength(64)],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
    title() {
      return this.detail ? "修改厂商" : "新增厂商";
    },
  },
  watch: {
    show(val) {
      if (val && this.detail) {
        this.form = {
          ...this.form,
          ...this.detail,
        };
      } else {
        this.form = this.$options.data().form;
        this.$refs.form?.resetFields();
      }
    },
  },
  methods: {
    handleOk() {
      let flag = false;
      this.$refs.form.validate((valid) => {
        flag = valid;
      });
      if (!flag) return;
      const api = this.detail ? apiManufacturerUpdate : apiManufacturerAdd;
      const params = { ...this.form };
      delete params?.manufacturerCode;
      api(params)
        .then(() => {
          this.$message.success(
            this.detail ? "修改厂商成功！" : "新增厂商成功！"
          );
          this.$emit("update", this.detail);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  height: 64px;
}
</style>
