<template>
  <div class="page-layout" v-loading="loading">
    <gc-header headerData="customerManage"></gc-header>
    <div class="run-main">
      <div class="serach">
        <gc-custom-search
          v-for="item in searchCondition"
          :key="item.key"
          :keyWord="item.label"
          :width="item.width || '300px'"
          :search.sync="form[item.key]"
          :type="item.type"
          :searchOption="options[item.key]"
          :needAllForSearch="item.needAllForSearch"
          :searchOptionKey="item.searchOptionKey"
        ></gc-custom-search>
        <div class="serach-ops">
          <el-button
            type="primary"
            v-click-blur
            @click="
              currentRowInfo = null;
              getList(1, apiGetCustomerList);
            "
            >查 询</el-button
          >
          <el-button
            @click="
              currentRowInfo = null;
              reset(apiGetCustomerList);
            "
            v-click-blur
            >重 置</el-button
          >
        </div>
        <gc-column-setting
          :storageKey="`localcolumn-customer-manage`"
          :columns="columns"
          @update-column="(data) => (columns = data)"
        ></gc-column-setting>
      </div>
      <div class="table-show">
        <gc-table
          :columns="columnList"
          :table-data="tableData"
          :border="true"
          :showPage="true"
          :total="page.total"
          :size="page.size"
          :currentPage="page.current"
          isHighlightCurrent
          @current-page-change="
            (currentPage) => {
              currentPageChange(currentPage, apiGetCustomerList);
            }
          "
          @row-click="handleRowClick"
        ></gc-table>
        <div class="customer-info">
          <CustomerInfoModal v-if="currentRowInfo" :info="currentRowInfo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "../runManage/mixins/common.js";
import { apiGetCustomerList } from "@/api/customerManage.js";
import CustomerInfoModal from "./component/CustomerInfoModal";
import { has } from "@/utils/business";
export default {
  name: "customerManage",
  mixins: [common],
  components: { CustomerInfoModal },
  data() {
    return {
      form: {
        // userType: null,
        userNo: null,
        // userName: null,
        // phone: null,
        // homeType: null,
      },
      options: {
        homeType: [
          { label: "是", value: 1 },
          { label: "否", value: 0 },
        ],
      },
      searchCondition: [
        // {
        //   key: "userType",
        //   label: "客户类型",
        //   type: "select",
        //   needAllForSearch: true,
        //   searchOptionKey: { label: "name", value: "defaultValue" },
        //   width: "250px",
        // },
        {
          key: "userNo",
          label: "用户号",
          width: "270px",
        },
        // {
        //   key: "userName",
        //   label: "客户名称",
        //   width: "270px",
        // },
        // {
        //   key: "phone",
        //   label: "联系电话",
        //   width: "270px",
        // },
        // {
        //   key: "homeType",
        //   label: "独居老人",
        //   type: "select",
        //   needAllForSearch: true,
        //   width: "250px",
        // },
      ],
      columns: [
        {
          key: "userNo",
          name: "用户号",
          fixed: "left",
          dragDisable: true,
          disabledSelect: true,
          tooltip: true,
        },
        {
          key: "userName",
          name: "客户名称",
          tooltip: true,
        },
        {
          key: "userTypeName",
          name: "客户类型",
        },
        {
          key: "homeTypeName",
          name: "标签",
          render: (h, row) => {
            return (
              <div>
                {row.homeTypeName ? (
                  <span style="background: #F2F6FF; padding: 2px 8px 3px 8px; display: inline-block; border-radius: 4px;">
                    {row.homeTypeName}
                  </span>
                ) : (
                  <span>--</span>
                )}
              </div>
            );
          },
        },
        {
          key: "phone",
          name: "联系电话",
        },
        {
          key: "addressName",
          name: "安装地址",
          tooltip: true,
        },
        {
          key: "bindDeviceNum",
          name: "关联设备数量",
        },
        {
          key: "alarmTotalNum",
          name: "当前报警数量",
          render: (h, row) => {
            return <div style="color: #ff0000">{row.alarmTotalNum || 0}</div>;
          },
        },
        has("monitor:ledger:user:gas:analysis")
          ? {
              key: "oprate",
              name: "操作",
              width: 100,
              fixed: "right",
              render: (h, row) => {
                return (
                  <div>
                    <el-button
                      type="text"
                      size="medium"
                      onClick={(event) => this.handleGasAnalysis(event, row)}
                    >
                      用气分析
                    </el-button>
                  </div>
                );
              },
            }
          : null,
      ].filter(Boolean),
      currentRowInfo: null,
    };
  },

  mounted() {
    this.$set(
      this.options,
      "userType",
      this.$store.getters.dataList.userType || []
    );
    this.getList(1, apiGetCustomerList);
  },

  methods: {
    apiGetCustomerList,

    handleGasAnalysis(event, { userNo }) {
      event.stopPropagation();
      this.$router.push({
        path: "/customer-manage/use-gas-analysis",
        query: {
          userNo,
        },
      });
    },

    handleRowClick({ row }) {
      if (this.currentRowInfo?.userNo === row.userNo) return;
      this.currentRowInfo = row;
    },
  },

  computed: {
    columnList() {
      return this.columns.filter((o) => o.selected);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../runManage/runLayout.scss";
.serach-ops {
  margin-bottom: 14px;
}
.table-show {
  display: flex;
  .gc-table {
    width: 0;
    flex: 1;
  }
  .customer-info {
    margin-left: 12px;
  }
}
</style>
