<template>
  <div class="basic-info">
    <div class="left">
      <div class="top">
        <div class="title">基本属性</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="info-item">
              <div class="label" style="width: 100px">设备类型名称：</div>
              <div class="value" style="width: calc(100% - 110px)">
                <Custom-Tooltip
                  refName="deviceTypeName"
                  :content="tabData.deviceTypeName"
                ></Custom-Tooltip>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="info-item">
              <div class="label">设备编号：</div>
              <div class="value">
                <Custom-Tooltip
                  refName="deviceNo"
                  :content="tabData.deviceNo"
                ></Custom-Tooltip>
              </div>
            </div>
          </el-col>
          <el-col :span="8" v-if="tabData.firstCategory === '0410'">
            <div class="info-item">
              <div class="label">条形编码：</div>
              <div class="value">
                <Custom-Tooltip
                  refName="gasDeviceNo"
                  :content="tabData.gasDeviceNo"
                ></Custom-Tooltip>
              </div>
            </div>
          </el-col>
          <el-col :span="8" v-for="item in basicInfoList" :key="item.key">
            <div class="info-item info-item-basic">
              <div class="label">{{ item.label }}</div>
              <div class="value">
                <Custom-Tooltip
                  :refName="item.key"
                  :content="item.value"
                ></Custom-Tooltip>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="bottom">
        <div class="title">安装属性</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="info-item">
              <div class="label">安装日期：</div>
              <div class="value">
                {{
                  (this.source === "timeoutDevice"
                    ? tabData.firstInstallDate
                    : tabData.installDate) || "--"
                }}
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="info-item">
              <div class="label">用户号：</div>
              <div class="value">
                <Custom-Tooltip
                  refName="userNo"
                  :content="tabData.userNo"
                ></Custom-Tooltip>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="info-item">
              <div class="label">客户类型：</div>
              <div class="value">
                <Custom-Tooltip
                  refName="userType"
                  :content="getUserTypeName()"
                ></Custom-Tooltip>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="info-item">
              <div class="label">
                {{
                  tabData.installLocation >= 10 ||
                  siteShow(tabData.deviceTypeId)
                    ? "站点名称："
                    : "客户名称："
                }}
              </div>
              <div class="value">
                <Custom-Tooltip
                  refName="username"
                  :content="tabData.username"
                ></Custom-Tooltip>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="info-item">
              <div class="label">联系电话：</div>
              <div class="value">
                <Custom-Tooltip
                  refName="userPhone"
                  :content="tabData.userPhone"
                ></Custom-Tooltip>
              </div>
            </div>
          </el-col>
          <el-col :span="8" v-if="tenantType == 1">
            <div class="info-item">
              <div class="label">是否独居老人：</div>
              <div class="value">
                <Custom-Tooltip
                  refName="homeType"
                  :content="
                    !isBlank(tabData.homeType)
                      ? homeTypeMap[tabData.homeType]
                      : '--'
                  "
                ></Custom-Tooltip>
              </div>
            </div>
          </el-col>
          <el-col :span="8" v-if="userInfo.tenantType == 1">
            <div class="info-item">
              <div class="label">初始表底：</div>
              <div class="value">
                <Custom-Tooltip
                  refName="startMeterReading"
                  :content="tabData.startMeterReading"
                ></Custom-Tooltip>
              </div>
            </div>
          </el-col>
          <el-col :span="8" v-if="userInfo.tenantType == 1">
            <div class="info-item">
              <div class="label">所属区域：</div>
              <div class="value">
                <Custom-Tooltip
                  refName="waterRegionName"
                  :content="tabData.waterRegionName"
                ></Custom-Tooltip>
              </div>
            </div>
          </el-col>
          <el-col
            :span="8"
            v-if="
              tabData.installLocation >= 10 || siteShow(tabData.deviceTypeId)
            "
          >
            <div class="info-item">
              <div class="label">站点编号：</div>
              <div class="value">
                {{ tabData.installLocationNo || "--" }}
              </div>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="info-item">
              <div class="label">安装位置：</div>
              <div class="value">
                {{ parseInstallLocationName(tabData.installLocation) || "--" }}
              </div>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="info-item">
              <div class="label">安装地址：</div>
              <div class="value">
                <!-- <Custom-Tooltip
                  refName="address"
                  :content="tabData.address"
                ></Custom-Tooltip> -->
                {{ tabData.addressName || "--" }}
              </div>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="info-item">
              <div class="label">设备位置：</div>
              <div class="value">{{ tabData.installPosition || "--" }}</div>
            </div>
          </el-col>
          <el-col :span="24" v-if="tabData.image && tabData.image.length">
            <div class="info-item install-pic">
              <div class="label">安装图片：</div>
              <div class="pic-list">
                <gc-image :urls="tabData.image"></gc-image>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="right">
      <div class="title">
        <span>时点运行状态</span>
        <span
          class="refresh-time"
          v-if="tabData.deviceStatus === 1 && refreshTime"
          >刷新时间：{{ refreshTime }}</span
        >
      </div>
      <div
        v-if="tabData.deviceStatus === 1 && cardList.length > 0"
        class="card"
      >
        <template v-for="item in cardList">
          <Status-Card :key="item.attributeKey" :card-info="item"></Status-Card>
        </template>
      </div>
      <gc-empty v-else style="margin-top: 220px"></gc-empty>
    </div>
  </div>
</template>

<script>
import StatusCard from "../../components/StatusCard";
import CustomTooltip from "@/components/components/customTooltip";
import { isBlank } from "@/utils/validate";
import { apiGetDeviceStatus } from "@/api/account";
import { siteShow } from "@/utils/business.js";
import { mapGetters } from "vuex";

export default {
  name: "basicInfo",
  components: { StatusCard, CustomTooltip },
  props: {
    tabData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      cardList: [],
      refreshTime: null,
      source: "",
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
      dataList: "dataList",
    }),
    tenantType() {
      return this.$store.getters.userInfo.tenantType;
    },
    basicInfoList() {
      let list = this.tabData.dynamicBaseAttributes || [];
      return list.map((item) => {
        let val = item.value;
        if (!isBlank(val)) {
          if (item.interactiveTranslationDes) {
            val = item.interactiveTranslationDes[val];
          }
          val = item.unitName ? val + item.unitName : val;
        } else {
          val = null;
        }
        return {
          label: item.name + "：",
          value: val,
          key: item.attributeKey,
        };
      });
    },
    homeTypeMap() {
      return (
        this.$store.getters.dataList?.homeType?.reduce((prev, cur) => {
          return {
            ...prev,
            [cur.defaultValue]: cur.name,
          };
        }, {}) || {}
      );
    },
  },
  methods: {
    isBlank,
    siteShow,
    // 解析安装位置名称
    parseInstallLocationName(value) {
      if (isBlank(value) || this.tabData.deviceStatus === 0) {
        return null;
      } else {
        const { installLocation = [] } = this.$store.getters.dataList;
        const target = installLocation.find(
          (item) => item.defaultValue == value
        );
        return target?.name;
      }
    },
    getDeviceStatus() {
      if (!this.$has("monitor:device:account:select")) return;
      let deviceId = this.$attrs.common;
      if (!deviceId) return;
      apiGetDeviceStatus(deviceId).then((res) => {
        let list = res.records || [];
        this.refreshTime = res.queryTime || null;
        this.cardList = list.map((item) => {
          let {
            value,
            interactiveTranslationDes,
            unitName,
            name,
            icon,
            style,
            attributeKey,
            valueType,
            timeSeriesDesc,
          } = item;
          if (interactiveTranslationDes) {
            value = interactiveTranslationDes[value];
          }
          return {
            value,
            unitName,
            name,
            icon,
            style,
            attributeKey,
            valueType,
            deviceId,
            timeSeriesDesc,
          };
        });
      });
    },

    getUserTypeName() {
      return (
        (
          this.dataList.userType.find(
            (item) => item.defaultValue == this.tabData.userType
          ) || {}
        ).name || null
      );
    },
  },
  created() {
    this.source = this.$route.query.source;
    this.getDeviceStatus();
  },
};
</script>
<style lang="scss" scoped>
.basic-info {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  height: 100%;
  overflow: auto;
  .left {
    flex: 1;
    min-width: 889px;
    display: flex;
    flex-direction: column;
    padding-right: 40px;
    padding-left: 24px;
    .top {
      padding: 34px 0 40px;
      border-bottom: 1px dashed #999999;
      ::v-deep [class*="el-col-"] {
        height: 44px;
      }
    }
    .bottom {
      padding-top: 43px;
    }
  }
  .right {
    flex: 1;
    max-width: 790px;
    padding-top: 30px;
    .card {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #333333;
    padding-right: 40px;
    .refresh-time {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
    }
  }
  .info-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    .label {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #666666;
      flex-shrink: 0;
    }
    .value {
      flex: 1;
      width: 0;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
    }
  }
  .install-pic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .pic-list {
      img {
        padding-top: 10px;
        vertical-align: top;
        width: 200px;
      }
      img + img {
        padding-left: 10px;
      }
    }
  }
}
</style>
