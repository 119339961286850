<template>
  <div class="upload">
    <div class="box">
      <img class="icon" src="@/assets/images/icon/download.svg" />
      <div class="content">
        <h2 class="title">下载需要的设备模板</h2>
        <p class="desc">
          请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除
        </p>
        <div class="btn" @click="visible = true">下载数据模板</div>
      </div>
    </div>
    <div class="box">
      <img class="icon" src="@/assets/images/icon/batch-upload.svg" />
      <div class="content">
        <h2 class="title">上传填写的设备数据</h2>
        <p class="desc">
          文件后缀名必须为xls 或xlsx （即Excel格式），文件大小不得大于10M
        </p>
        <div class="btn" v-show="!fileName" @click="handleUpload">上传文件</div>
        <div v-show="fileName" class="file-name">
          {{ fileName }}({{ fileSize }})
          <i
            class="iconfont el-icon-circle-close"
            @click="handleDeleteFile"
          ></i>
        </div>
      </div>
    </div>
    <div class="box-btn">
      <el-button
        type="primary"
        :disabled="!fileName"
        @click="handleConfirmUpload"
      >
        确认上传
      </el-button>
    </div>

    <!-- 下载数据模板弹框 -->
    <data-template-dialog :show.sync="visible"></data-template-dialog>
    <!-- 上传文件前确认模板信息 -->
    <data-template-dialog
      type="upload"
      ok-text="下一步"
      :show.sync="visibleUpload"
      @file-info="getFileInfo"
    >
    </data-template-dialog>
  </div>
</template>

<script>
import DataTemplateDialog from "./DataTemplateDialog.vue";
import { isBlank, isObject, isString, isNumber } from "@/utils/validate";
import { mapMutations, mapGetters } from "vuex";
import { formatExcelDate } from "@/utils";

export default {
  name: "StepUpload",
  components: { DataTemplateDialog },
  data() {
    return {
      visible: false,
      visibleUpload: false,
      fileName: "",
      fileSize: "",
      excelList: [],
      loading: null,
    };
  },
  computed: {
    ...mapGetters({
      dataTemplate: "account/dataTemplate",
      excelColumns: "account/excelColumns",
    }),
  },
  methods: {
    ...mapMutations({
      setSuccessData: "account/SET_SUCCESS_DATA",
      setFailData: "account/SET_FAIL_DATA",
    }),
    handleUpload() {
      this.visibleUpload = true;
    },
    getFileInfo(file) {
      const { fileName, fileSize, fileData } = file;
      this.fileName = fileName;
      this.fileSize = fileSize;
      this.excelList = fileData;
    },
    handleDeleteFile() {
      this.fileName = "";
      this.fileSize = "";
      this.excelList = [];
    },
    handleConfirmUpload() {
      if (!this.excelColumns.length) {
        this.$message.error("请先下载需要的设备模板");
        return false;
      }
      if (!this.fileName) {
        this.$message.error("请上传填写的设备数据");
        return false;
      }
      if (!this.excelList.length) {
        this.$message.error("设备数据不能为空");
        return false;
      }
      const dataColumns = Object.keys([...this.excelList][0]);
      if (dataColumns.length !== this.excelColumns.length) {
        this.$message.error("上传表格与下载模板不符，请使用下载模板进行填写");
        return;
      }
      this.loading = this.$loading({ text: "正在上传，请等待…" });
      for (let i = 0, len = dataColumns.length; i < len; i++) {
        const target = this.excelColumns.find(
          (item) => item.name == dataColumns[i]
        );
        if (!target) {
          this.$message.error(
            `上传表格与下载模板不符，模板不包含${dataColumns[i]}`
          );
          this.loading.close();
          return;
        }
      }
      this.checkExcelData();
    },
    checkExcelData() {
      const { deviceTypeName } = this.dataTemplate;
      const successData = [];
      const failData = [];
      const tableColumns = [...this.excelColumns];
      const excelData = [...this.excelList];
      excelData.map((obj, index) => {
        obj["行数"] = index + 2;
        obj["设备类型"] = deviceTypeName;
        const remark = [];
        const deviceNos = excelData.filter(
          (o) => o["设备编号"] == obj["设备编号"]
        );
        if (deviceNos.length > 1) {
          remark.push(`设备编号重复`);
        }
        tableColumns.map((item) => {
          const {
            name,
            optional,
            lengthCheck,
            inputCheck,
            valueType,
            attributeKey,
          } = item;
          const value = obj[name];
          // 日期格式校验
          if (valueType == 1 && !isBlank(value)) {
            let date = value;
            if (isString(value)) {
              date = this.dayjs(value).format("YYYY-MM-DD");
            } else if (isNumber(value)) {
              date = formatExcelDate(value);
            }
            const isDateFormat = /^([1-9]\d{3})-(\d{2})-(\d{2})$/.test(date);
            obj[name] = isDateFormat ? date : value;
            if (!isDateFormat) remark.push(`${name}格式(yyyy/mm/dd)不正确`);
          }
          // 证书有效时间不允许填写日期格式
          if (valueType == 0 && attributeKey === "certificateValidityPeriod") {
            const date = this.dayjs(value).format("YYYY-MM-DD");
            const isDateFormat = /^([1-9]\d{3})-(\d{2})-(\d{2})$/.test(date);
            if (isDateFormat) remark.push(`${name}不能填写日期格式`);
          }
          // 必填校验
          if (optional == 1 && isBlank(value)) {
            remark.push(`${name}不能为空`);
          }
          // 联动检验
          if (
            isObject(optional) &&
            obj[optional.linkName] == optional?.linkValue &&
            isBlank(value)
          ) {
            remark.push(`${name}不能为空`);
          }
          // 长度校验
          if (lengthCheck && value?.length > lengthCheck) {
            remark.push(`${name}最大长度不能超过${lengthCheck}`);
          }

          // 正则校验-接口返回属性
          if (inputCheck?.reg) {
            const { reg, regMsg } = inputCheck;
            let pattern = reg;
            if (typeof reg === "string") {
              pattern = new RegExp(reg);
            }
            if (!pattern.test(value) && !isBlank(value)) {
              remark.push(regMsg);
            }
          }

          // 正则校验
          if (
            inputCheck?.pattern &&
            !inputCheck?.pattern.test(value) &&
            !isBlank(value)
          ) {
            remark.push(inputCheck?.message);
          }
          if (remark.length) {
            obj["异常说明"] = remark.join("；");
          }
        });
        if (obj["异常说明"]) {
          failData.push(obj);
        } else {
          successData.push(obj);
        }
        this.setSuccessData(successData);
        this.setFailData(failData);
      });
      this.loading.close();
      this.$emit("upload");
    },
  },
};
</script>
<style lang="scss" scoped>
.upload {
  width: 70%;
  margin: 0 auto;

  .box {
    border-radius: 4px;
    border: 1px dashed #d9d9d9;
    display: flex;
    align-items: center;
    + .box {
      margin-top: 20px;
    }
    .icon {
      width: 136px;
      height: 136px;
    }
    .content {
      padding: 31px 16px 25px;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #333;
      }
      .desc {
        margin-top: 16px;
        color: #c9c9c9;
      }
      .btn {
        margin-top: 19px;
        cursor: pointer;
        color: $base-color-theme;
      }
      .file-name {
        margin-top: 19px;
        color: #666;
        display: flex;
        align-items: center;
        .iconfont {
          margin-left: 25px;
          cursor: pointer;
          color: #000;
        }
      }
    }
  }

  .box-btn {
    margin: 64px auto 24px;
    text-align: center;
  }
}
</style>
