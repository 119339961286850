var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g(_vm._b({directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"container"},'div',_vm.$attrs,false),_vm.$listeners),[_c('el-form',{ref:"contentForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"80px","label-position":"top"}},[_c('el-row',{staticStyle:{"margin":"0 !important"},attrs:{"gutter":20}},[_c('div',{staticClass:"device-dialog-content"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v("基本属性")]),_c('div',{staticClass:"content"},[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"deviceTypeId",attrs:{"prop":"deviceTypeId","label":"设备类型名称","rules":[
                  {
                    required: true,
                    message: '请选择设备类型名称',
                    trigger: '',
                  } ]}},[_c('el-select',{attrs:{"placeholder":"请选择设备类型","filterable":"","disabled":_vm.basicInfoDisabled},on:{"change":_vm.deviceTypeChange,"visible-change":_vm.getTenantDeviceTypeList},model:{value:(_vm.form.deviceTypeId),callback:function ($$v) {_vm.$set(_vm.form, "deviceTypeId", $$v)},expression:"form.deviceTypeId"}},_vm._l((_vm.options.deviceTypeId),function(option){return _c('el-option',{key:option.value,attrs:{"value":option.value,"label":option.label}})}),1)],1)],1),_c('el-col',{attrs:{"span":8}},[(_vm.form.firstCategory === '0410')?_c('el-form-item',{ref:"gasDeviceNo",attrs:{"prop":"gasDeviceNo","label":"条形编码","rules":[
                  {
                    required: true,
                    message: '请输入条形编码',
                    trigger: 'blur',
                  } ]}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.basicInfoDisabled},on:{"change":_vm.handleChangeDeviceBarcode},model:{value:(_vm.form.gasDeviceNo),callback:function ($$v) {_vm.$set(_vm.form, "gasDeviceNo", $$v)},expression:"form.gasDeviceNo"}})],1):_vm._e()],1),_c('el-col',{attrs:{"span":8}},[(_vm.form.connectType !== 2)?_c('el-form-item',{ref:"deviceNo",attrs:{"prop":"deviceNo","label":"设备编号","rules":[
                  {
                    required: true,
                    message: '请输入设备编号',
                    trigger: 'blur',
                  },
                  {
                    max: 32,
                    message: '设备编号不可以超过32位',
                    trigger: '',
                  } ]}},[_c('el-input',{attrs:{"placeholder":_vm.form.firstCategory === '0410'
                      ? '输入条形编码自动获取设备编号'
                      : '请输入',"disabled":_vm.basicInfoDisabled || _vm.form.firstCategory === '0410'},model:{value:(_vm.form.deviceNo),callback:function ($$v) {_vm.$set(_vm.form, "deviceNo", $$v)},expression:"form.deviceNo"}})],1):_vm._e()],1),_c('el-col',{attrs:{"span":8}},[(_vm.form.connectType === 2)?_c('el-form-item',{ref:"deviceNo",attrs:{"prop":"deviceNo","label":"网关编号","rules":[
                  {
                    required: true,
                    message: '请输入网关编号',
                    trigger: '',
                  },
                  {
                    max: 32,
                    message: '网关编号不可以超过32位',
                    trigger: '',
                  } ]}},[_c('el-autocomplete',{staticClass:"inline-input",attrs:{"placeholder":"请填写","value-key":"deviceNo","fetch-suggestions":_vm.queryGatewayDeviceId,"trigger-on-focus":false,"disabled":_vm.type !== 'add'},on:{"select":_vm.handleSelect,"input":function($event){return _vm.requiredInputChange($event, 'deviceNo')}},model:{value:(_vm.form.deviceNo),callback:function ($$v) {_vm.$set(_vm.form, "deviceNo", $$v)},expression:"form.deviceNo"}})],1):_vm._e()],1),_c('el-col',{attrs:{"span":8}},[(_vm.form.connectType === 2)?_c('el-form-item',{ref:"slaveNo",attrs:{"prop":"slaveNo","label":"子机号","rules":[
                  {
                    required: true,
                    message: '请输入子机号',
                    trigger: 'blur',
                  },
                  {
                    max: 2,
                    message: '子机号不可以超过2位',
                    trigger: '',
                  } ]}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.type !== 'add'},on:{"input":function($event){return _vm.requiredInputChange($event, 'slaveNo')}},model:{value:(_vm.form.slaveNo),callback:function ($$v) {_vm.$set(_vm.form, "slaveNo", $$v)},expression:"form.slaveNo"}})],1):_vm._e()],1),_c('el-col',{attrs:{"span":8}},[(_vm.form.manufacturerName)?_c('el-form-item',{ref:"manufacturerName",attrs:{"label":"厂商名称"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.form.manufacturerName),callback:function ($$v) {_vm.$set(_vm.form, "manufacturerName", $$v)},expression:"form.manufacturerName"}})],1):_vm._e()],1),_vm._l((_vm.basicFormItems),function(item){return [(item.attributeKey !== 'subDeviceCount')?_c('el-col',{key:item.attributeKey,attrs:{"span":8}},[_c('el-form-item',{ref:item.attributeKey,refInFor:true,attrs:{"prop":item.attributeKey,"label":item.name,"rules":item.rules}},[(item.valueType === '2')?_c('el-select',{attrs:{"placeholder":"请选择","disabled":item.disabled || _vm.basicInfoDisabled},model:{value:(_vm.form[item.attributeKey]),callback:function ($$v) {_vm.$set(_vm.form, item.attributeKey, $$v)},expression:"form[item.attributeKey]"}},[(item.needAll)?_c('el-option',{attrs:{"value":null}},[_vm._v("全部")]):_vm._e(),_vm._l((item.interactiveTranslationDes),function(value,key){return _c('el-option',{key:key,attrs:{"value":key,"label":value}})})],2):(item.valueType === '1')?_c('el-date-picker',{attrs:{"type":"date","placeholder":"选择日期","disabled":item.disabled || _vm.basicInfoDisabled},model:{value:(_vm.form[item.attributeKey]),callback:function ($$v) {_vm.$set(_vm.form, item.attributeKey, $$v)},expression:"form[item.attributeKey]"}}):_c('el-input',{attrs:{"placeholder":"请输入","disabled":item.disabled ||
                      (_vm.basicInfoDisabled &&
                        !['ICCID', 'IMEI', '口径'].includes(item.name))},model:{value:(_vm.form[item.attributeKey]),callback:function ($$v) {_vm.$set(_vm.form, item.attributeKey, $$v)},expression:"form[item.attributeKey]"}},[(item.unitname)?_c('i',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v(" "+_vm._s(item.unitname)+" ")]):_vm._e()])],1)],1):_vm._e()]})],2)]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"title"},[_vm._v("安装属性")]),_c('div',{staticClass:"content"},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{ref:"deviceStatus",staticClass:"is-radio",attrs:{"prop":"deviceStatus","label":"是否已安装","rules":[
                  { required: true, message: '请选择安装状态', trigger: '' } ]}},[_c('el-radio-group',{attrs:{"disabled":_vm.type !== 'add'},on:{"change":_vm.deviceStatusChange},model:{value:(_vm.form.deviceStatus),callback:function ($$v) {_vm.$set(_vm.form, "deviceStatus", $$v)},expression:"form.deviceStatus"}},_vm._l(([
                      {
                        label: '未安装',
                        value: 0,
                      },
                      {
                        label: '已安装',
                        value: 1,
                      } ]),function(option){return _c('el-radio',{key:option.label,attrs:{"label":option.value}},[_vm._v(_vm._s(option.label))])}),1)],1)],1)],1),(_vm.form.deviceStatus === 1)?_c('div',{staticClass:"content"},[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"installDate",attrs:{"prop":"installDate","label":"安装日期","rules":[
                  {
                    required: _vm.form.deviceStatus == 1,
                    message: '安装日期不可为空',
                    trigger: '',
                  } ]}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"选择日期"},model:{value:(_vm.form.installDate),callback:function ($$v) {_vm.$set(_vm.form, "installDate", $$v)},expression:"form.installDate"}})],1)],1),(_vm.siteShow(_vm.form.deviceTypeId))?_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"installLocation",attrs:{"prop":"installLocation","label":"安装位置"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.form.installLocation),callback:function ($$v) {_vm.$set(_vm.form, "installLocation", $$v)},expression:"form.installLocation"}},_vm._l((_vm.installLocationOptionsGas),function(item){return _c('el-option',{key:item.label,attrs:{"value":+item.value,"label":item.label}})}),1)],1)],1):_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"installLocation",attrs:{"prop":"installLocation","label":"安装位置"}},[_c('el-cascader',{attrs:{"options":_vm.installLocationOptions,"show-all-levels":false},on:{"change":_vm.installLocationChange},model:{value:(_vm.installLocations),callback:function ($$v) {_vm.installLocations=$$v},expression:"installLocations"}})],1)],1),(_vm.showSite)?_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"installLocationNo",attrs:{"prop":"installLocationNo","label":"站点编号","rules":[
                  {
                    max: 20,
                    message: "站点编号不可超过20个字符",
                    trigger: '',
                  } ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.installLocationNo),callback:function ($$v) {_vm.$set(_vm.form, "installLocationNo", $$v)},expression:"form.installLocationNo"}})],1)],1):_vm._e(),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"userNo",attrs:{"prop":"userNo","label":"用户号","rules":[
                  {
                    max: 128,
                    message: '用户号不可超过128个字符',
                    trigger: '',
                  } ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.userNo),callback:function ($$v) {_vm.$set(_vm.form, "userNo", $$v)},expression:"form.userNo"}})],1)],1),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"userType",attrs:{"prop":"userType","label":"客户类型","rules":[
                  {
                    required: _vm.form.deviceStatus == 1 && !_vm.specialForZhoukou,
                    message: '请选择客户类型',
                    trigger: '',
                  } ]}},[_c('el-select',{attrs:{"placeholder":"请选择","disabled":_vm.specialForZhoukou},on:{"change":_vm.userTypeChange},model:{value:(_vm.form.userType),callback:function ($$v) {_vm.$set(_vm.form, "userType", $$v)},expression:"form.userType"}},[(false)?_c('el-option',{attrs:{"value":null}},[_vm._v("全部")]):_vm._e(),_vm._l((_vm.options.userType),function(option){return _c('el-option',{key:option.defaultValue,attrs:{"value":+option.defaultValue,"label":option.name}})})],2)],1)],1),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"username",attrs:{"prop":"username","label":_vm.showSite ? '站点名称' : '客户名称',"rules":[
                  {
                    required: _vm.form.deviceStatus == 1,
                    message: ((_vm.showSite ? '站点名称' : '客户名称') + "不可为空"),
                    trigger: '',
                  },
                  {
                    max: 128,
                    message: ((_vm.showSite ? '站点名称' : '客户名称') + "不可超过128个字符"),
                    trigger: '',
                  } ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},on:{"blur":function($event){return _vm.nameOrPhone('username')}},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1)],1),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"userPhone",attrs:{"prop":"userPhone","label":"联系电话","rules":[
                  {
                    required:
                      _vm.form.deviceStatus == 1 &&
                      (_vm.form.userType == 2 || _vm.form.userType == 3),
                    message: '手机号不可为空',
                    trigger: '',
                  },
                  {
                    pattern: /^1[3456789]\d{9}$/,
                    message: '输入手机号格式不对，11位且13-19开头',
                    trigger: '',
                  } ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},on:{"blur":function($event){return _vm.nameOrPhone('userPhone')}},model:{value:(_vm.form.userPhone),callback:function ($$v) {_vm.$set(_vm.form, "userPhone", $$v)},expression:"form.userPhone"}})],1)],1),(_vm.tenantType == 1)?_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"homeType",attrs:{"prop":"homeType","label":"是否独居老人"}},[_c('el-radio-group',{model:{value:(_vm.form.homeType),callback:function ($$v) {_vm.$set(_vm.form, "homeType", $$v)},expression:"form.homeType"}},_vm._l((_vm.options.homeType),function(option){return _c('el-radio',{key:option.defaultValue,attrs:{"label":Number(option.defaultValue)}},[_vm._v(_vm._s(option.name))])}),1)],1)],1):_vm._e(),(_vm.tenantType == 1)?_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"startMeterReading",attrs:{"prop":"startMeterReading","label":"初始表底","rules":[
                  {
                    required: _vm.form.deviceStatus == 1,
                    message: '初始表底不可为空',
                    trigger: '',
                  } ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.startMeterReading),callback:function ($$v) {_vm.$set(_vm.form, "startMeterReading", $$v)},expression:"form.startMeterReading"}})],1)],1):_vm._e(),(_vm.tenantType == 1)?_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{ref:"waterRegionCode",attrs:{"prop":"waterRegionCode","label":"所属区域","rules":[
                  {
                    required: true,
                    message: '请选择所属区域',
                    trigger: '',
                  } ]}},[_c('gc-tree-select',{attrs:{"placeholder":"请选择所属区域","props":{
                    label: 'name',
                    id: 'code',
                    disabled: function (data) {
                      return data.unSelectFlag && data.unSelectFlag === 1;
                    },
                  },"options":_vm.treeOptions},model:{value:(_vm.form.waterRegionCode),callback:function ($$v) {_vm.$set(_vm.form, "waterRegionCode", $$v)},expression:"form.waterRegionCode"}})],1)],1):_vm._e(),_c('el-col',{attrs:{"span":8}},[_c('el-form-item')],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{ref:"useGasPositon",attrs:{"label":"安装地址","required":_vm.form.deviceStatus == 1}},[_c('Use-Gas',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingUseGas),expression:"loadingUseGas"}],ref:"useGas",attrs:{"form":_vm.form,"required":_vm.form.deviceStatus == 1,"location-options":_vm.locationOptions,"loading-use-gas":_vm.loadingUseGas,"additionalInfo":_vm.additionalInfo},on:{"update:form":function($event){_vm.form=$event},"update:loadingUseGas":function($event){_vm.loadingUseGas=$event},"update:loading-use-gas":function($event){_vm.loadingUseGas=$event},"requestLocation":_vm.requestLocation}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{ref:"installPositon",staticClass:"install-position",attrs:{"prop":"lngLat","label":"设备位置","rules":[
                  {
                    required: _vm.form.deviceStatus == 1 && _vm.specialForZhoukou,
                    message: '请选择设备位置',
                    trigger: '',
                  } ]}},[_c('el-input',{attrs:{"placeholder":"经纬度信息","disabled":""},model:{value:(_vm.form.lngLat),callback:function ($$v) {_vm.$set(_vm.form, "lngLat", $$v)},expression:"form.lngLat"}}),_c('span',{on:{"click":_vm.showMap}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-ditu"}})])])],1)],1)],1):_vm._e()])])])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.showMapBox)?_c('div',{staticClass:"map-box"},[_c('Map-Select',{ref:"mapSelect",attrs:{"form":_vm.form},on:{"current-position":_vm.getPosition}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }