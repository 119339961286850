// 本地存储相关方法

import Vue from "vue";
import Storage from "vue-ls";
import _ from "lodash";

const namespace = "iot_v20_";

Vue.use(_.clone(Storage), {
  namespace,
  name: "ls",
  storage: "local",
});

export const getLS = (key) => Vue.ls.get(key);
export const setLS = (key, value) => Vue.ls.set(key, value);
export const removeLS = (key) => Vue.ls.remove(key);
export const clearLS = () => {
  const keys = Object.keys(localStorage);
  keys.forEach((key) => {
    // 清除除多语言配置外的所有缓存
    if (!key.includes("localcolumn")) {
      localStorage.removeItem(key); // 如果键不在保留列表中，移除它
    }
  });
};

Vue.use(_.clone(Storage), {
  namespace,
  name: "ss",
  storage: "session",
});

export const getSS = (key) => Vue.ss.get(key);
export const setSS = (key, value) => Vue.ss.set(key, value);
export const removeSS = (key) => Vue.ss.remove(key);
export const clearSS = () => Vue.ss.clear();
