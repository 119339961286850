<template>
  <div class="check">
    <div class="box-tip" v-if="!failData.length">
      <img class="icon" src="@/assets/images/icon/success.svg" />
      <p class="text">本次可导入设备数据</p>
      <span class="amount">{{ successData.length }}条</span>
    </div>
    <div class="box-tip warning" v-else>
      <img class="icon" src="@/assets/images/icon/warning.svg" />
      <p class="text">本次不可导入设备数据</p>
      <span class="amount">{{ failData.length }}条</span>
    </div>
    <div class="box-btn">
      <el-button
        type="primary"
        class="btn-next"
        :disabled="!!failData.length"
        @click="handleNext"
      >
        下一步
      </el-button>
      <el-button
        type="primary"
        plain
        class="btn-reupload"
        @click="handleReupload"
      >
        重新上传
      </el-button>
    </div>
    <div class="box-table" v-if="failData.length">
      <h2 class="title">异常记录</h2>
      <gc-table
        class="table"
        :columns="columns"
        :table-data="failData"
        :border="true"
      ></gc-table>
    </div>
  </div>
</template>

<script>
import { isBlank } from "@/utils/validate";
import { apiBatchImport } from "@/api/account";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "StepCheck",
  data() {
    return {
      importFailData: [],
      loading: null,
    };
  },
  computed: {
    ...mapGetters({
      dataTemplate: "account/dataTemplate",
      deviceView: "account/deviceView",
      deviceTotalAttrs: "account/deviceTotalAttrs",
      excelColumns: "account/excelColumns",
      successData: "account/successData",
      failData: "account/failData",
      dataList: "dataList",
      userInfo: "userInfo",
    }),
    tenantType() {
      return this.$store.getters.userInfo.tenantType;
    },
    columns() {
      const tableColumns = this.excelColumns.map((item) => {
        return { key: item.name, name: item.name, tooltip: true };
      });
      tableColumns.unshift({
        key: "行数",
        name: "行数",
        fixed: "left",
        width: 80,
      });
      tableColumns.push({
        key: "异常说明",
        name: "异常说明",
        fixed: "right",
        width: 300,
        tooltip: true,
      });
      return tableColumns;
    },
  },
  methods: {
    ...mapMutations({
      setSuccessData: "account/SET_SUCCESS_DATA",
      setFailData: "account/SET_FAIL_DATA",
      setImportFailData: "account/SET_IMPORT_FAIL_DATA",
    }),
    // 点击下一步，处理请求参数
    handleNext() {
      if (this.failData.length) return;
      const data = [...this.successData];
      const totalAttrs = [...this.deviceTotalAttrs];
      const { deviceTypeId, optType, deviceStatus, areaCode, waterRegionCode } =
        this.dataTemplate;
      const totalList = [];
      const getValueMap = (key) => {
        return (
          this.dataList[key]?.reduce((prev, cur) => {
            return {
              ...prev,
              [cur.name]: cur.defaultValue,
            };
          }, {}) || {}
        );
      };
      const homeTypeMap = getValueMap("homeType");
      const userTypeMap = getValueMap("userType");
      data.map((item, index) => {
        const obj = {
          dynamicContent: {},
        };

        totalAttrs.map((attr) => {
          const { name, productAttributeId, attributeKey, defaultValue } = attr;
          if (
            productAttributeId ||
            attributeKey === "subDeviceCount" ||
            attributeKey === "pressureCount"
          ) {
            // 设备类型关联属性参数
            obj.dynamicContent[attributeKey] =
              isBlank(defaultValue) ||
              attributeKey === "subDeviceCount" ||
              attributeKey === "pressureCount"
                ? item[name]
                : defaultValue;
          } else {
            if (attributeKey === "userType") {
              // 客户类型解析
              // let findItem = this.dataList.userType.find(
              //   (d) => d.name === item[name]
              // );
              // obj.userType = findItem.defaultValue;
              obj.userType = userTypeMap[item[name]];
            } else if (attributeKey === "homeType") {
              // 是否独居老人解析

              obj.homeType = homeTypeMap[item[name]];
            } else if (attributeKey === "installLocation") {
              // 安装位置解析
              obj.installLocation = this.parseInstallLocationValue(item[name]);
            } else {
              obj[attributeKey] = item[name];
            }
          }
        });
        if (areaCode) {
          obj.areaCode = areaCode[2];
        }
        const { manufacturerId, manufacturerName, nodeType } = this.deviceView;
        totalList.push({
          ...obj,
          lineNumber: index + 2,
          connectType: nodeType || 0, // 网关设备连接类型为1
          deviceTypeId,
          deviceStatus,
          manufacturerId,
          manufacturerName,
          waterRegionCode:
            this.tenantType == 1
              ? deviceStatus == 0
                ? this.userInfo.defaultWaterRegionCode
                : waterRegionCode
              : null,
        });
      });
      this.loading = this.$loading({ text: "正在导入，请等待…" });
      this.startImport(totalList, optType);
    },
    // 开始导入，接口每次最多上传500条数据
    async startImport(totalList, optType) {
      const paramList = this.cutArray(totalList, 500);
      const res = await this.getImportResult(paramList, optType);
      this.loading.close();
      const failList = [];
      res.map((item) => {
        if (item.status === "fulfilled") {
          failList.push(...(item?.value || []));
        }
      });
      this.setImportFailData(failList);
      this.$emit("next");
    },
    async getImportResult(array, optType) {
      const queue = [];
      array.forEach((item) => {
        const params = {
          dataList: item,
          optType,
        };
        queue.push(apiBatchImport(params));
      });
      const result = await Promise.allSettled(queue);
      return result;
    },
    // 请求参数切割
    cutArray(array, subLength) {
      let index = 0;
      let newArr = [];
      while (index < array.length) {
        newArr.push(array.slice(index, (index += subLength)));
      }
      return newArr;
    },
    // 解析安装位置value值
    parseInstallLocationValue(name) {
      const { installLocation = [] } = this.$store.getters.dataList;
      const target = installLocation.find((item) => item.name == name);
      return target?.defaultValue || "";
    },
    handleReupload() {
      this.setSuccessData([]);
      this.setFailData([]);
      this.setImportFailData([]);
      this.$emit("reupload");
    },
  },
};
</script>
<style lang="scss" scoped>
.check {
  .box-tip {
    margin: 0 auto;
    width: 40%;
    min-width: 400px;
    max-width: 640px;
    height: 64px;
    background: #e4f5ec;
    border-radius: 4px;
    display: flex;
    align-items: center;
    + .box-tip {
      margin-top: 10px;
    }
    &.warning {
      background: #fdf5e7;
      .amount {
        color: #ffac05;
      }
    }
    .icon {
      width: 64px;
      height: 64px;
    }
    .text {
      margin-right: 8px;
      width: 165px;
      color: #333;
    }
    .amount {
      font-size: 24px;
      font-weight: 500;
      color: #16a65d;
    }
  }

  .box-btn {
    margin: 46px auto 24px;
    text-align: center;
    .el-button {
      min-width: 100px;
      + .el-button {
        margin-left: 13px;
      }
    }
  }

  .box-table {
    margin-top: 50px;
    border-top: 1px dashed #d9d9d9;
    height: calc(100vh - 620px);
    min-height: 200px;
    .title {
      line-height: 55px;
      font-size: 16px;
      font-weight: 500;
      color: #333;
    }
  }
}
</style>
