/* 1、地址最长输入字符限制 */
<template>
  <div>
    <gc-dialog
      :title="modelStatus"
      :show.sync="visible"
      @cancel="cancelUpdate"
      @ok="confirmUpdate"
      width="610px"
    >
      <el-form
        ref="staffModelForm"
        :rules="rules"
        :model="staffModel"
        :disabled="status === 'view'"
      >
        <div class="pale-title">基本信息</div>
        <el-form-item label="员工姓名" prop="name">
          <el-input
            size="small"
            v-model="staffModel.name"
            placeholder="请填写"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属部门" prop="orgId" v-if="orgEnable && showOrg">
          <el-cascader
            ref="orgRef"
            @change="handleOrgChange"
            style="width: 100%"
            :options="departmentTree"
            v-model="staffModel.orgId"
            :disabled="status !== 'add'"
            :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
            }"
            popper-class="zindexpopper"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="所属角色" prop="roleIds">
          <el-select
            multiple
            :collapse-tags="status !== 'view'"
            size="small"
            v-model="staffModel.roleIds"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="tenantType == 1 && treeOptions.length"
          ref="waterRegionCode"
          prop="additionalInfo.waterRegionCode"
          label="所属区域"
        >
          <gc-tree-select-multiple
            multiple
            :disabled="status === 'view'"
            v-model="staffModel.additionalInfo.waterRegionCode"
            placeholder="请选择所属区域"
            :tree-props="{ id: 'code', label: 'name' }"
            :options="treeOptions"
          ></gc-tree-select-multiple>
        </el-form-item>
        <el-form-item label="员工编号" prop="additionalInfo.staffNo">
          <el-input
            size="small"
            v-model="staffModel.additionalInfo.staffNo"
            placeholder="请填写"
          ></el-input>
        </el-form-item>
        <el-form-item label="在职状态" prop="enable">
          <el-select
            size="small"
            v-model="staffModel.enable"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options.resignedTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="additionalInfo.gender" label="性别">
          <el-select
            v-model="staffModel.additionalInfo.gender"
            placeholder="请选择 "
          >
            <el-option
              v-for="item in options.genderOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="additionalInfo.mobile">
          <el-input
            size="small"
            :maxlength="11"
            v-model="staffModel.additionalInfo.mobile"
            placeholder="请填写"
          ></el-input>
        </el-form-item>
        <el-form-item label="办公电话" prop="additionalInfo.phone">
          <el-input
            size="small"
            v-model="staffModel.additionalInfo.phone"
            placeholder="请填写"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="additionalInfo.idCardNo">
          <el-input
            size="small"
            v-model="staffModel.additionalInfo.idCardNo"
            placeholder="请填写"
          ></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="additionalInfo.remark" class="col-12">
          <el-input
            size="small"
            v-model="staffModel.additionalInfo.remark"
            placeholder="请填写"
          ></el-input>
        </el-form-item>
        <template v-if="status === 'add'">
          <div class="split-line" />
          <div class="pale-title">账户信息</div>
          <el-form-item
            label="登录账号"
            prop="account"
            class="margin-right-20px"
          >
            <el-input
              v-model="staffModel.account"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="登录密码"
            :required="true"
            prop="password"
            class="original"
          >
            <el-input
              disabled
              v-model="staffModel.password"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
        </template>
      </el-form>
      <template #footer v-if="status === 'view'">
        <el-button plain round @click="visible = false">关闭</el-button>
      </template>
    </gc-dialog>
  </div>
</template>

<script>
import {
  ruleRequired,
  ruleMaxLength,
  RULE_PHONE,
  RULE_INCORRECTIDCARD,
  RULE_INCORRECTEMAIL,
} from "@/utils/rules";
import { apiAddStaff, apiModifyStaff } from "@/api/organizeStaff";
import { apiGetRoleList } from "@/api/organizeRole";
import { encrypt } from "@/utils";
import { mapActions } from "vuex";

export default {
  name: "AddStaff",
  components: {},
  props: {
    departmentTree: {},
  },
  data() {
    return {
      staffModel: {
        name: "", //员工名称
        orgId: [], //所属租户组织机构
        roleIds: [], //所属角色ID
        account: "", //账号名
        password: "jk300349",
        enable: true,
        additionalInfo: {
          gender: 0,
          idCardNo: "",
          phone: "",
          mobile: "",
          staffNo: "",
          remark: "",
          email: "",
          address: "",
          scope: "",
          waterRegionCode: "",
        },
      },
      rules: {
        // 待办：办公电话规则
        name: [ruleRequired("请填写员工姓名"), ruleMaxLength(32)],
        orgId: [ruleRequired("请选择所属部门")],
        roleIds: [ruleRequired("请选择所属角色")],
        "additionalInfo.staffNo": [ruleMaxLength(16)], // ruleRequired("请填写员工编号"),
        "additionalInfo.mobile": [RULE_PHONE],
        "additionalInfo.idCardNo": [RULE_INCORRECTIDCARD],
        "additionalInfo.email": [RULE_INCORRECTEMAIL],
        "additionalInfo.address": [ruleMaxLength(32)],
        "additionalInfo.waterRegionCode": [ruleRequired("至少选择一个区域 ")],
        account: [ruleRequired("请填写登录账号"), ruleMaxLength(32)],
        password: [ruleRequired("请填写登录密码")],
      },
      status: "add",
      visible: false,
      roleOptions: [],
      showOrg: true,
    };
  },
  computed: {
    options() {
      const genderOptions = [
          {
            value: 0,
            label: "男",
          },
          {
            value: 1,
            label: "女",
          },
        ],
        resignedTypeOptions = [
          {
            value: true,
            label: "在职",
          },
          {
            value: false,
            label: "离职",
          },
        ];
      const roleTreeOptions = [];
      const occupationOptions = [];

      return {
        genderOptions,
        resignedTypeOptions,
        roleTreeOptions,
        occupationOptions,
      };
    },
    modelStatus() {
      return {
        edit: "修改员工信息",
        add: "新增员工",
        view: "查看详情",
      }[this.status];
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    orgEnable() {
      return this.userInfo.isOrgEnable;
    },
    treeOptions() {
      return this.$store.getters.treeOptions;
    },
    tenantType() {
      // 0-燃气租户 1-水务租户
      return this.$store.getters.userInfo.tenantType || "0";
    },
  },
  watch: {
    visible(newVal) {
      this.$refs.staffModelForm?.clearValidate();

      if (!newVal) {
        this.staffModel = this.$options.data().staffModel;
        this.$refs.staffModelForm?.resetFields();
      } else {
        this.tenantType == 1 &&
          this.getTreeOptions().then((data) => {
            if (this.status !== "add") return;
            if (data.length) {
              this.staffModel.additionalInfo.waterRegionCode = data[0].code;
            } else {
              const waterRegionInitCode =
                this.$store.getters.dataList.waterRegionInitCode;
              this.staffModel.additionalInfo.waterRegionCode =
                waterRegionInitCode[0].defaultValue;
            }
          });
      }
    },
  },
  activated() {
    this.getRoleList();
  },
  methods: {
    ...mapActions({
      getTreeOptions: "apiCache/getTreeOptions",
    }),
    handleOrgChange() {
      this.$refs.orgRef?.toggleDropDownVisible();
      this.staffModel.roleIds?.length > 0 &&
        this.$set(this.staffModel, "roleIds", []);
    },
    getRoleList() {
      apiGetRoleList().then(({ dataList }) => {
        if (!dataList || dataList.length === 0) return (this.roleList = []);
        this.roleOptions = dataList;
      });
    },
    getOrgId(id) {
      const arr = [],
        obj = {};
      const helper = (list, prefix) => {
        list.forEach((item) => {
          const { children, ...rest } = item;
          arr.push(rest);
          obj[prefix + "," + item.id] = item.id;
          children && helper(children, prefix + "," + item.id);
        });
      };

      helper(this.departmentTree, "");
      const item = Object.entries(obj).filter(([key, value]) => {
        const keyList = key.split(",");
        const lastChild = keyList[keyList.length - 1];
        return value == id && lastChild == id;
      })[0];
      const res = item[0].split(",");
      res.shift();
      return res.map((item) => Number(item));
    },
    viewDetail(staffInfo) {
      const { org, roles, ...rest } = {
        ...staffInfo,
        additionalInfo: staffInfo.additionalInfo || {},
      };
      this.staffModel = this._.cloneDeep({
        orgId: org ? this.getOrgId(org.id) : [],
        roleIds: roles.map((item) => item.id),
        ...rest,
      });
      this.status = "view";
      this.visible = true;
      this.showOrg = false;
      this.$nextTick(() => {
        this.showOrg = true;
      });
    },
    add() {
      this.status = "add";
      this.visible = true;
    },
    edit(staffInfo) {
      const { org, roles, ...rest } = {
        ...staffInfo,
        additionalInfo: staffInfo.additionalInfo || {},
      };
      this.staffModel = this._.cloneDeep({
        orgId: org ? this.getOrgId(org.id) : [],
        roleIds: roles ? roles.map((item) => item.id) : [],
        ...rest,
      });
      this._.omit(this.staffModel, ["account", "password"]);
      this._.omit(this.rules, ["account", "password"]);
      this.status = "edit";
      this.visible = true;
      this.showOrg = false;
      this.$nextTick(() => {
        this.showOrg = true;
      });
    },
    confirmUpdate() {
      this.$refs.staffModelForm.validate((valid) => {
        if (!valid) return;
        const req = this.status === "add" ? apiAddStaff : apiModifyStaff;

        const { orgId, password, additionalInfo, ...rest } = this.staffModel;
        req({
          ...rest,
          additionalInfo: {
            ...additionalInfo,
            waterRegionCode:
              this.tenantType == 1 ? additionalInfo.waterRegionCode : undefined,
          },
          orgId: orgId[orgId.length - 1],
          password: password ? encrypt(password) : undefined,
        }).then(() => {
          this.$message.success(
            this.status === "add" ? "新增成功" : "修改成功"
          );
          this.visible = false;
          this.$refs.staffModelForm.resetFields();
          this.$refs.staffModelForm.clearValidate();
          this.$emit("refresh");
        });
      });
    },
    cancelUpdate() {
      this.visible = false;
      this.$refs.staffModelForm.resetFields();
      this.$refs.staffModelForm.clearValidate();
    },
  },
};
</script>
<style lang="scss" scoped>
.split-line {
  flex: 1;
  border: 0.5px dashed #ccc;
  height: 0;
  transform: scaleY(0.5);
  margin-bottom: 20px;
}
::v-deep .el-form {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  .pale-title {
    flex: 0 0 100%;
    font-size: 14px;
    color: #ababab;
    line-height: 21px;
    margin-bottom: 20px;
  }
  .el-form-item {
    flex: 0 0 calc((100% - 30px) / 2);
    margin-right: 0;
    margin-bottom: 20px;
    &:nth-child(2n),
    &.margin-right-20px {
      margin-right: 30px;
    }
    &.original {
      margin-right: 0;
    }
    &.col-12 {
      flex: 0 0 100%;
      margin-right: 0;
    }
    .el-form-item__label {
      line-height: 14px;
      height: 14px;
      margin-bottom: 10px;
      font-weight: 600;
    }
    .el-select {
      width: 100%;
    }
    .el-input.is-disabled .el-input__icon {
      display: none;
    }
  }
}
</style>
