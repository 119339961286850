<template>
  <div class="page-layout" v-loading="loading">
    <gc-header headerData="pressureAnalysis">
      <template #ops>
        <el-button type="primary" @click="handleExport">导 出</el-button>
      </template>
    </gc-header>
    <div class="run-main">
      <div class="serach">
        <gc-custom-search
          width="300px"
          key-word-width="90px"
          :key-word-option="keyWordOption"
          :key-word.sync="keyWord"
          :search.sync="form.key"
        ></gc-custom-search>
        <gc-custom-search
          v-for="item in searchCondition"
          :key="item.key"
          :keyWord="item.label"
          :width="item.width || '300px'"
          :search.sync="form[item.key]"
          :type="item.type"
          :searchOption="options[item.key]"
          :needAllForSearch="item.needAllForSearch"
          :datePickerType="
            item.type == 'date' ? item.datePickerType : 'daterange'
          "
          :searchOptionKey="item.searchOptionKey"
          :required="item.required"
        ></gc-custom-search>
        <div class="serach-ops">
          <el-button type="primary" @click="handleQuery" v-click-blur
            >查 询</el-button
          >
          <el-button @click="pageInit" v-click-blur>重 置</el-button>
        </div>
        <gc-column-setting
          :storageKey="`localcolumn-pressure-analysis`"
          :columns="columns"
          @update-column="(data) => (columns = data)"
        ></gc-column-setting>
      </div>
      <div class="table-show">
        <gc-table
          :columns="columnList"
          :table-data="tableData"
          :border="true"
          :showPage="true"
          :total="page.total"
          :size="page.size"
          :currentPage="page.current"
          @current-page-change="
            (currentPage) => {
              currentPageChange(
                currentPage,
                apiPressureAnalysisPreview,
                'date',
                keyWord
              );
            }
          "
        ></gc-table>
      </div>
    </div>

    <detail-dialog
      :show.sync="showDetailDialog"
      :detail="detail"
      :default-time="form.time"
    ></detail-dialog>
  </div>
</template>

<script>
import { apiPressureAnalysisPreview } from "@/api/pressureAnalysis";
import common from "../mixins/common.js";
import deviceTypeOption from "../mixins/deviceTypeOption.js";
import { isObject } from "@/utils/validate.js";
import { mapActions } from "vuex";
import { exportReport } from "@/api/statisticAnalysis";
import DetailDialog from "./DetailDialog.vue";

export default {
  name: "pressureAnalysis",
  components: { DetailDialog },
  mixins: [common, deviceTypeOption],
  data() {
    return {
      keyWordOption: [
        {
          label: "设备编号",
          value: "deviceNo",
        },
        {
          label: "地址",
          value: "address",
        },
      ],
      keyWord: "deviceNo",
      form: {
        key: null,
        username: null, //名称
        deviceTypeId: null, //设备类型
        time: [], //时间区间
      }, //筛选条件
      searchCondition: [
        { key: "username", label: "客户/站点名称" },
        {
          key: "deviceTypeId",
          label: "设备类型",
          type: "select",
          needAllForSearch: true,
          searchOptionKey: {
            label: "deviceTypeName",
            value: "deviceTypeId",
          },
        },
        {
          key: "time",
          label: "日期区间",
          type: "date",
          datePickerType: "datetimerange",
          width: "450px",
          required: true,
        },
      ],
      options: {}, //下拉列表
      columns: [],
      detail: {},
      showDetailDialog: false,
    };
  },
  mounted() {
    this.pageInit();
    this.getDeviceTypeOptions();
  },
  methods: {
    ...mapActions({
      getDeviceTypes: "common/getAllDeviceTypeList",
    }),
    pageInit() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      start.setHours(0, 0, 0);
      end.setHours(23, 59, 59);
      this.form = this.$options.data().form;
      this.keyWord = this.$options.data().keyWord;
      // 默认最近一个月
      this.form.time = [start, end];
      this.getList(1, apiPressureAnalysisPreview, "date", this.keyWord);
    },
    getDeviceTypeOptions() {
      this.getDeviceTypes().then((data) => {
        this.$set(this.options, "deviceTypeId", data);
      });
    },
    apiPressureAnalysisPreview,
    handleExport() {
      if (!this.tableData.length) {
        this.$message.warning("暂无表格数据");
        return;
      }
      this.$toast("下载中，请稍后...");
      const data = {
        method: "post",
        url: "/monitor/report/pressure-analysis/export/excel",
        fileName: "压力分析.xlsx",
        data: {
          ...this.form,
          startDate: this.form.time
            ? this.dayjs(this.form.time[0]).format("YYYY-MM-DD HH:mm:ss")
            : null,
          endDate: this.form.time
            ? this.dayjs(this.form.time[1]).format("YYYY-MM-DD HH:mm:ss")
            : null,
        },
      };
      exportReport(data, "excel", null, this.$toast);
    },
    handleQuery() {
      if (!this.form.time) {
        this.$message.warning("请选择日期区间");
        return;
      }
      this.getList(1, apiPressureAnalysisPreview, "date", this.keyWord);
    },
    handleDetail(row) {
      this.detail = row;
      this.showDetailDialog = true;
    },
  },
  computed: {
    pressureUnitName() {
      return this.$store.getters.pressureUnitName;
    },

    columnList() {
      return this.columns.filter((o) => o.selected);
    },
  },
  watch: {
    pressureUnitName: {
      handler() {
        this.columns = [
          {
            key: "deviceNo",
            name: "设备编号",
            fixed: "left",
            dragDisable: true,
            disabledSelect: true,
          },
          { key: "deviceTypeName", name: "设备类型" },
          { key: "username", name: "客户/站点名称" },
          { key: "phone", name: "联系电话" },
          { key: "address", name: "地址" },
          {
            key: "underPressureMin",
            name: `压力下下限（${this.pressureUnitName}）`,
          },
          {
            key: "underPressureMax",
            name: `压力下限（${this.pressureUnitName}）`,
          },
          {
            key: "overPressureMin",
            name: `压力上限（${this.pressureUnitName}）`,
          },
          {
            key: "overPressureMax",
            name: `压力上上限（${this.pressureUnitName}）`,
          },
          { key: "pressureMax", name: `最大压力（${this.pressureUnitName}）` },
          { key: "maxDataDate", name: "最大压力时间", width: "160" },
          { key: "pressureMin", name: `最小压力（${this.pressureUnitName}）` },
          { key: "minDataDate", name: "最小压力时间", width: "160" },
          { key: "pressureAvg", name: `压力均值（${this.pressureUnitName}）` },
          {
            key: "oprate",
            name: "操作",
            minWidth: 200,
            render: (h, row) => {
              //  自定义表格内容展示
              const childList = [
                h(
                  "el-button",
                  {
                    props: {
                      type: "text",
                      size: "medium",
                    },
                    on: {
                      click: () => this.handleDetail(row),
                    },
                  },
                  "查看详情"
                ),
              ];
              const hasVNode = childList.some((item) => isObject(item));
              return h("div", {}, hasVNode ? childList : "/");
            },
            fixed: "right",
          },
        ];
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../runLayout.scss";
.serach-ops {
  margin-bottom: 14px;
}
</style>
