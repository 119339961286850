<template>
  <div class="flow-meter" v-loading="loading">
    <div class="left-box flow-meter-left">
      <div class="flow-meter-show" v-if="deviceDataList.length > 0">
        <div class="flow-meter-left-title">设备信息</div>
        <gc-custom-search
          class="flow-meter-left-search"
          key-word="设备选择"
          type="select"
          :search.sync="currentDevice"
          :search-option="deviceDataList"
          :search-option-key="{
            label: 'subDeviceNo',
            value: 'subDeviceId',
          }"
        />
        <div class="device-item">
          <div class="device-item-info">
            <div
              class="label-value"
              v-for="item in channelDeviceField"
              :key="item.key"
            >
              <span class="label">{{ item.name }}：</span>
              <span class="value">{{
                !isBlank(currentDeviceObj[item.key])
                  ? currentDeviceObj[item.key] + item.unit
                  : "--"
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <gc-empty v-else pageType="channelInfo"></gc-empty>
    </div>
    <div class="right-box">
      <charts-box
        ref="charts-box"
        :activeChart="activeChart"
        :tabData="tabData"
        :deviceDataList="deviceDataList"
        :currentDeviceObj="currentDeviceObj"
        :params.sync="params"
        :echartsFlag="echartsFlag"
        @analysis="handleAnalysis"
      ></charts-box>
    </div>
  </div>
</template>

<script>
import { isBlank } from "@/utils/validate";
import chartMix from "./mixin";
import ChartsBox from "@/components/components/chart/index.vue";

export default {
  name: "FlowerMeter",
  mixins: [chartMix],
  components: { ChartsBox },
  props: {},
  data() {
    return {
      channelDeviceField: [],
      currentDevice: "", //当前选中的设备id
      currentDeviceObj: {}, //当前选中的设备信息
    };
  },
  watch: {
    activeChart(val) {
      // 初次默认请求一个月
      if (val === "channel" && isBlank(this.params.time)) {
        this.getData(val, this.$refs["charts-box"].checkedDimension);
      }
    },
    deviceDataList(val) {
      if (val && val.length > 0) {
        this.currentDevice = val[0].subDeviceId;
        this.currentDeviceObj = val[0];
      }
    },
    currentDevice(val) {
      if (!isBlank(val)) {
        this.currentDeviceObj =
          this.deviceDataList.find((item) => item.subDeviceId == val) || {};
        let statusArr = [];
        Object.keys(this.currentDeviceObj.status).forEach((key) => {
          statusArr.push(this.currentDeviceObj.status[key]);
        });
        this.echartsFlag = !statusArr.every((item) => item.length == 0);
      }
    },
    pressureUnitName: {
      handler() {
        this.channelDeviceField = [
          {
            key: "standardCumulativeFlow",
            name: "标况总量",
            unit: "m³",
          },
          {
            key: "standardInstantaneousFlow",
            name: "标况瞬时流量",
            unit: "m³/h",
          },
          {
            key: "pressure",
            name: "压力",
            unit: this.pressureUnitName,
          },
          {
            key: "mainVoltage",
            name: "电压",
            unit: "V",
          },
          {
            key: "workCumulativeFlow",
            name: "工况总量",
            unit: "m³",
          },
          {
            key: "workInstantaneousFlow",
            name: "工况瞬时流量",
            unit: "m³/h",
          },

          {
            key: "gasTemperature",
            name: "温度",
            unit: "℃",
          },
          {
            key: "cleanedReading",
            name: "表读数",
            unit: "m³",
          },
        ];
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    isBlank,
    handleAnalysis(checked) {
      this.getData(this.activeChart, checked);
    },
  },
  computed: {
    pressureUnitName() {
      return this.$store.getters.pressureUnitName;
    },
  },
};
</script>
<style lang="scss" scoped></style>
