import axios from "axios";
import { JSEncrypt } from "jsencrypt";
import { clearLS } from "@/utils/storage";
import { isBlank, isString } from "./validate";
// 常见工具方法
export const getPrevIndex = (arr, curr) => {
  if (Object.prototype.toString.call(arr) !== "[object Array]") {
    return -1;
  }

  if (arr.length < 2) return -1;
  if (arr.indexOf(curr) <= 0) {
    return -1;
  }
  return arr.indexOf(curr) - 1;
};

// 参数对象去空 字符串去前后空格
export const handleParams = (params) => {
  let obj = {};
  for (let key in params) {
    if (!isBlank(params[key])) {
      if (isString(params[key])) {
        obj[key] = params[key].trim();
      } else {
        obj[key] = params[key];
      }
    }
  }
  return obj;
};

// 数据字典转换
export const nameTrans = (value, arr) => {
  return (arr.find((item) => item.defaultValue == value) || {})?.name || "--";
};

// 字符串指定位置插入字符
export const insertStr = (soure, start, newStr) => {
  return soure.slice(0, start) + newStr + soure.slice(start);
};

// 格式化excel导出时间
export const formatExcelDate = (numb, format = "yyyy-mm-dd") => {
  if (numb > 0) {
    const time = new Date((numb - 1) * 24 * 3600000 + 1);
    let h = time.getHours() + 16;
    let yeraData = new Date(1900, 0, numb - 1);
    let year = yeraData.getFullYear();
    let month = yeraData.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = yeraData.getDate();
    day = day < 10 ? "0" + day : day;
    if (h > 23) {
      h = h - 24;
    }
    h < 10 ? (h = "0" + h) : null;
    let m =
      time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
    let s =
      time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
    if (format === "yyyy-mm-dd") {
      return `${year}-${month}-${day}`;
    }
    return `${year}-${month}-${day} ${h}:${m}:${s}`;
  } else {
    return "非法日期格式";
  }
};

export const formatTime = (type = "y-m-d", date = new Date()) => {
  if (date instanceof Date === false) {
    date = new Date(+date);
  }
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const formatNumber = (n) => {
    const str = n.toString();
    return str[1] ? str : "0" + str;
  };
  const yearMonthDay = [year, month, day].map(formatNumber).join("-");
  const monthDay = [month, day].map(formatNumber).join("-");
  const hourMinuteSecond = [hour, minute, second].map(formatNumber).join(":");

  switch (type) {
    case "h:m:s":
      return hourMinuteSecond;
    case "y-m-d h:m:s":
      return yearMonthDay + " " + hourMinuteSecond;
    case "m-d":
      return monthDay;
    case "y-m-d":
    default:
      return yearMonthDay;
  }
};

//   等保三级认证，密码至少包含两种字符组合(数字/字母/特殊符号)
export const isComplexPass = (value) => {
  let totalPoint = 0,
    rule_digit = 0,
    rule_letter = 0,
    rule_special = 0;
  // 数字
  if (value.match(/\d+/g)) {
    rule_digit = 1;
  }
  // 字母
  if (value.match(/[a-zA-Z]+/g)) {
    rule_letter = 1;
  }
  // 特殊字符
  if (value.match(/[^\w]+/g)) {
    rule_special = 1;
  }
  totalPoint = rule_digit + rule_letter + rule_special;
  return totalPoint < 2 ? false : true;
};

/**
 * 加密
 * @param { String } data 需要加密的数据
 * @param { String } key 公钥key
 * @param { String } iv
 */
export const encrypt = (data) => {
  const publicKey =
    "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMRpvV7/iqttMdvvCQzm2E7ZqnRWGp15RwHrYf9aUsN8KScoQ49VN8uhLJu07HKoWwlul28vfgBqnUKJ25R36b8CAwEAAQ==";
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  return encryptor.encrypt(data);
};

// 根据域名是否包含eslink子域名判断是否为etbc租户
export const isEtbcTenant = window.ETBC_DOMAIN.some(
  (domain) => location.origin.indexOf(domain) > -1
);

export const etbcLogout = async () => {
  clearLS();
  location.replace(window.ETBC_LOGOUT_URL);
};

// 根据域名是否包含 szrqbjq 判断是否为深圳燃气租户
export const isSzrqTenant = location.origin.indexOf("szrqbjq") > -1;

export const jumpScreen = async () => {
  const url =
    window.location.protocol === "http:"
      ? "http://cls.test.eslink.net.cn"
      : "https://gsscreen.eslink.com";
  window.open(url, "_blank"); //  'noopener=yes,noreferrer=yes'
};
