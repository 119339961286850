var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"water-home-page"},[_c('div',{staticClass:"top-region-pick"},[(_vm.treeOptions.length)?_c('gc-custom-search',{staticClass:"search",attrs:{"key-word":"所属区域","type":"tree-select","width":"380px","show-checkbox":"","search":_vm.ssqy,"searchOption":_vm.treeOptions,"propsObj":{
        label: 'name',
        children: 'children',
        id: 'code',
        disabled: _vm.disabledFn,
      }},on:{"update:search":function($event){_vm.ssqy=$event}}}):_vm._e()],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left-modal modal"},[_c('div',{staticClass:"statistic-card"},[_vm._m(0),_c('div',{staticClass:"right-data"},[_c('div',{staticClass:"label-item",on:{"click":function($event){return _vm.handleNumClick({
                userType: 4,
                queryDimension: 0,
                title: '工商表',
              })}}},[_c('div',{staticClass:"title"},[_vm._v("工商表数量")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.bigWatch.businessMeterCount))])]),_c('div',{staticClass:"label-item",on:{"click":function($event){return _vm.handleNumClick({
                userType: 5,
                queryDimension: 0,
                title: '考核表',
              })}}},[_c('div',{staticClass:"title"},[_vm._v("考核表数量")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.bigWatch.assessmentMeterCount))])]),_c('div',{staticClass:"label-item",on:{"click":function($event){return _vm.handleNumClick({
                userType: 6,
                queryDimension: 0,
                title: '流量计',
              })}}},[_c('div',{staticClass:"title"},[_vm._v("流量计数量")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.bigWatch.flowMeterCount))])]),_c('div',{staticClass:"label-item",on:{"click":function($event){return _vm.handleNumClick({
                queryDimension: 4,
                title: '远传大表-昨日未采集',
              })}}},[_c('div',{staticClass:"title"},[_vm._v("昨日未采集")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.bigWatch.yesterdayNoUpCount))])])])]),_c('DevicesTable',{attrs:{"waterRegionCode":_vm.ssqy},on:{"change-code":_vm.handleChangeCode}}),_c('ManufacturerPie',{attrs:{"waterRegionCode":_vm.ssqy}})],1),_c('div',{staticClass:"right-modal modal"},[_c('div',{staticClass:"statistic-card"},[_vm._m(1),_c('div',{staticClass:"right-data"},[_c('div',{staticClass:"label-item",on:{"click":function($event){return _vm.handleNumClick({
                userType: 3,
                queryDimension: 0,
                title: '远传小表-水表数量',
              })}}},[_c('div',{staticClass:"title"},[_vm._v("水表数量")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.smallWatch.meterCount))])]),_c('div',{staticClass:"label-item",on:{"click":function($event){return _vm.handleNumClick({
                queryDimension: 1,
                title: '远传小表-本月新增',
                queryDimension: 5,
                title: '采集器',
              })}}},[_c('div',{staticClass:"title"},[_vm._v("采集器")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.smallWatch.collectorCount))])]),_c('div',{staticClass:"label-item",on:{"click":function($event){return _vm.handleNumClick({
                queryDimension: 2,
                title: '远传小表-昨日未采集',
              })}}},[_c('div',{staticClass:"title"},[_vm._v("昨日未采集")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.smallWatch.yesterdayNoUpCount))])]),_c('div',{staticClass:"label-item",on:{"click":function($event){return _vm.handleNumClick({
                queryDimension: 3,
                title: '远传小表-上月未采集',
              })}}},[_c('div',{staticClass:"title"},[_vm._v("上月未采集")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.smallWatch.lastMonthNoUpCount))])])])]),_c('WaterMeterLine',{attrs:{"waterRegionCode":_vm.ssqy}})],1)]),(_vm.tableVisible)?_c('WaterMeterTableList',{attrs:{"currentRow":_vm.currentRow},on:{"close-modal":function($event){_vm.tableVisible = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon/yuanchuandabiao.svg"),"alt":""}}),_c('p',{staticClass:"cn-name"},[_vm._v("远传大表")]),_c('p',{staticClass:"en-name"},[_vm._v("The big watch")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-icon"},[_c('img',{attrs:{"src":require("@/assets/images/icon/yuanchuanxiaobiao.svg"),"alt":""}}),_c('p',{staticClass:"cn-name"},[_vm._v("远传小表")]),_c('p',{staticClass:"en-name"},[_vm._v("Remote watch")])])}]

export { render, staticRenderFns }