var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gc-region-tree"},[(_vm.filterable)?_c('div',{staticClass:"tree-search"},[_c('gc-custom-search',{attrs:{"width":"100%","placeholder":"输入区域名称进行搜索","search":_vm.searchValue},on:{"update:search":function($event){_vm.searchValue=$event}}})],1):_vm._e(),_c('div',{staticClass:"tree-content"},[_c('el-tree',{ref:"gloabRegionTree",attrs:{"node-key":_vm.valueKey,"data":!_vm.lazy ? _vm.treeRegionOptions : null,"highlight-current":!_vm.showSelect,"expand-on-click-node":false,"filter-node-method":_vm.filterNode,"indent":12,"props":_vm.propsObj,"show-checkbox":_vm.showSelect,"check-strictly":false,"default-expand-all":_vm.expandAll,"lazy":_vm.lazy,"load":_vm.loadNode,"default-expanded-keys":_vm.defaultExpandedKeys},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
return _c('div',{staticClass:"custom-tree-node",class:{
          hasCheckbox: node.data.hasCheckBox && _vm.showSelect,
          noHighLight: _vm.showSelect,
          disabled: _vm.isDisabled(node),
        },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleNodeClick(node.data)}}},[_c('img',{staticClass:"icon",class:{
            w_15: node.level > 1 && !node.data.deviceId,
            w_17: node.data.deviceId,
          },attrs:{"src":_vm.getRegionTreeIcon(node),"alt":""}}),_c('p',[_vm._v(_vm._s(node.label))])])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }