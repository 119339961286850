var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gc-dialog',{attrs:{"show":_vm.visible,"title":_vm.title,"ok-text":_vm.okText,"show-footer":_vm.status !== 2},on:{"update:show":function($event){_vm.visible=$event},"ok":_vm.handleOk,"cancel":function($event){_vm.visible = false},"close":function($event){_vm.visible = false}}},[(_vm.status === 1)?_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.formRules,"label-position":"top"}},[_c('el-form-item',{attrs:{"label":"设备类型名称","prop":"deviceTypeId"}},[_c('el-select',{attrs:{"filterable":"","placeholder":"请选择"},model:{value:(_vm.form.deviceTypeId),callback:function ($$v) {_vm.$set(_vm.form, "deviceTypeId", $$v)},expression:"form.deviceTypeId"}},_vm._l((_vm.deviceTypeList),function(item){return _c('el-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)],1),(_vm.tenantType == 1 && _vm.form.optType != 0)?_c('el-form-item',{attrs:{"label":"所属区域","prop":"waterRegionCode"}},[_c('gc-tree-select',{attrs:{"placeholder":"请选择所属区域","props":{
          label: 'name',
          id: 'code',
          disabled: function (data) {
            return data.unSelectFlag && data.unSelectFlag === 1;
          },
        },"options":_vm.treeOptions},model:{value:(_vm.form.waterRegionCode),callback:function ($$v) {_vm.$set(_vm.form, "waterRegionCode", $$v)},expression:"form.waterRegionCode"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"操作类型","prop":"optType"}},[_c('el-radio-group',{on:{"change":_vm.handleRadioChange},model:{value:(_vm.form.optType),callback:function ($$v) {_vm.$set(_vm.form, "optType", $$v)},expression:"form.optType"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("仅新增设备")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("新增并安装设备")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("仅安装设备")])],1)],1),(_vm.form.deviceStatus && _vm.type !== 'download')?_c('el-form-item',{attrs:{"label":"安装地址","prop":"areaCode","rules":[
        {
          validator: _vm.adressCodeRule,
          trigger: '',
        } ]}},[_c('el-cascader',{attrs:{"options":_vm.locationOptions,"placeholder":"请选择","popper-class":"gc-customer-cascader"},model:{value:(_vm.form.areaCode),callback:function ($$v) {_vm.$set(_vm.form, "areaCode", $$v)},expression:"form.areaCode"}})],1):_vm._e()],1):_vm._e(),(_vm.status === 2)?_c('div',{staticClass:"box-upload"},[_c('el-upload',{ref:"upload",staticClass:"btn",attrs:{"action":"","drag":"","auto-upload":false,"show-file-list":false,"on-change":_vm.handleFileChange}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("将文件拖到此处，或"),_c('em',[_vm._v("点击上传")])])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }