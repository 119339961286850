// 多天不上传统计
export default {
  header: {
    pic: "statistical-analysis",
    title: "多天不上传统计",
    desc: "对多天不上传数据的设备进行统计分析。",
  },
  filterArr: [
    {
      title: "统计条件",
      filterList: [
        {
          key: "manufacturerId",
          type: "select",
          keyWord: "厂商名称",
        },
        {
          key: "deviceTypeId",
          type: "select",
          keyWord: "设备类型",
        },
        // {
        //   key: "operator",
        //   type: "select",
        //   keyWord: "运营商",
        // },
        {
          key: "deviceNo",
          keyWord: "设备编号",
        },
        {
          key: "day",
          keyWord: "不上传天数",
          unit: "天",
          required: true,
          default: 1,
        },
        {
          key: "userNo",
          keyWord: "用户号",
        },
        {
          key: "username",
          keyWord: "客户名称",
        },
      ],
    },
  ],
  formRules: {
    day: [
      {
        required: true,
        message: "请输入不上传天数",
        trigger: ["change", "blur"],
      },
      {
        validator: (rule, value, callback) => {
          const reg = /^([0]|[1-9][0-9]*)$/;
          if (!reg.test(value)) {
            callback(new Error("请输入正整数"));
            return;
          }
          if (value < 0) {
            callback(new Error("请输入天数大于0"));
            return;
          }
          if (value > 255) {
            callback(new Error("最大天数不能超过255"));
            return;
          }
          callback();
        },
        trigger: ["change", "blur"],
      },
    ],
  },
  columns: [
    {
      key: "manufacturerName",
      name: "厂商名称",
    },
    // {
    //   key: "operator",
    //   name: "运营商",
    // },
    {
      key: "deviceTypeName",
      name: "设备类型",
    },
    {
      key: "deviceNo",
      name: "设备编号",
    },
    {
      key: "lastCommunicationTime",
      name: "最后通讯日期",
    },
    {
      key: "userNo",
      name: "用户号",
    },
    {
      key: "username",
      name: "客户名称",
    },
    {
      key: "address",
      name: "安装地址",
    },
  ],
};
