<template>
  <div class="common-shell">
    <gc-header :header-data="headerData">
      <template #ops>
        <el-button
          v-click-blur
          style="width: 100px"
          type="primary"
          @click="exportTable"
          >导出报表</el-button
        >
      </template>
    </gc-header>
    <div class="common-shell-content">
      <div class="left-filter">
        <el-form
          ref="reportForm"
          :model="params"
          :rules="rednderConfig.formRules"
        >
          <div
            class="filter-modules"
            v-for="item in rednderConfig.filterArr"
            :key="item.title"
          >
            <div class="title">{{ item.title }}</div>
            <div
              class="item"
              v-for="filter in handleFilters(item.filterList)"
              :key="filter.key"
            >
              <el-form-item :prop="filter.key">
                <gc-custom-search
                  :type="filter.type || ''"
                  :key-word="filter.keyWord"
                  :required="filter.required"
                  :width="filter.width || '330px'"
                  :key-word-width="filter.keyWordWidth"
                  :search.sync="params[filter.key]"
                  :need-all-for-search="filter.needAll === false ? false : true"
                  :search-option="
                    filter.options || searchOption[filter.key] || []
                  "
                  :filterable="filter.filterable || false"
                  :disabled="
                    searchOption[filter.key] && !searchOption[filter.key].length
                  "
                  :date-picker-type="filter.datePickerType"
                  :class-date-time="filter.datePickerType === 'datetimerange'"
                  :select-clearable="filter.clearable || false"
                  :clearable="filter.clearable"
                >
                  <template v-if="filter.icon || filter.unit" v-slot:icon>
                    <i
                      v-if="filter.icon"
                      :class="['iconfont', `${filter.icon}`]"
                    ></i>
                    <span v-if="filter.unit">{{ filter.unit }}</span>
                  </template>
                </gc-custom-search>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="button-zone">
          <el-button v-click-blur type="primary" @click="query"
            >查 询</el-button
          >
          <el-button v-click-blur @click="reset">重 置</el-button>
        </div>
      </div>
      <div class="right-table">
        <div class="table" v-loading="loading">
          <div
            class="water-statistic"
            v-if="
              totalStatistic &&
              tenantType == 1 &&
              ['deviceDevelop', 'alarmDeal'].includes(type)
            "
          >
            <div class="t">合计</div>
            <div
              class="statistic-item"
              v-for="item in columns.filter((o) => o.hasTotal)"
              :key="item.key"
            >
              <p>{{ item.name }}</p>
              <span>{{ totalStatistic[item.key] }}</span>
            </div>
          </div>
          <div class="table-content">
            <gc-table
              ref="table"
              border
              showPage
              :total="total"
              :columns="columns"
              :table-data="tableData"
              :current-page.sync="currentPage"
              @current-page-change="pageChange"
            ></gc-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const exportMaps = {
  deviceDevelop: {
    url: "/monitor/report/device-develop/export/excel",
    fileName: "设备发展量统计.xls",
  },
  alarmDeal: {
    url: "/monitor/report/alarm-process/export/excel",
    fileName: "告警处理情况统计.xls",
  },
  factoryAlarm: {
    url: "/monitor/report/device-alarm/export/excel",
    fileName: "厂商告警设备对比.xls",
  },
  meterRate: {
    url: "/monitor/report/meter-reading/export/excel",
    fileName: "抄表率统计.xls",
  },
  manyDaysNoUpload: {
    url: "/monitor/report/exception/no-upload/export/excel",
    fileName: "多天不上传统计.xls",
  },
  valveOperate: {
    url: "/monitor/report/exception/valve-opt/export/excel",
    fileName: "阀门操作统计.xls",
  },
};

const statisticMap = {
  deviceDevelop: apiGetStaticsDeviceDevelop,
  alarmDeal: apiGetStaticsAlarmProcess,
};
import pageMap from "../pageConfig/index";
import {
  exportReport,
  apiGetDeviceDevelopList,
  apiGetAlarmProcessList,
  apiGetDeviceAlarmList,
  apiGetFirstCategoryList,
  apiGetSecondCategoryList,
  apiGetManufacturerList,
  apiGetDeviceTypeList,
  apiGetEventModelList,
  apiReportMeterReading,
  apiReportNoUpload,
  apiReportValve,
  apiGetStaticsDeviceDevelop,
  apiGetStaticsAlarmProcess,
} from "@/api/statisticAnalysis";
import { statisDimension } from "@/views/statisticAnalysis/pageConfig/const";
import { transDataList } from "@/utils/business";
import { isBlank } from "@/utils/validate";
import { mapGetters } from "vuex";

export default {
  name: "",
  components: {},
  props: {
    type: String,
  },
  data() {
    return {
      tableData: [],
      params: {},
      searchOption: {},
      loading: false,
      total: 0,
      currentPage: 1,
      dynamicColumns: [],
      paramsBak: {},
      totalStatistic: null,
    };
  },
  computed: {
    rednderConfig() {
      let config = pageMap[this.type];
      config.filterArr = config.filterArr.map((item) => {
        return {
          ...item,
          filterList: item.filterList.filter(
            (el) => isBlank(el.tenantType) || el.tenantType === this.tenantType
          ),
        };
      });
      config.columns = config.columns.filter(
        (el) => isBlank(el.tenantType) || el.tenantType === this.tenantType
      );
      return config;
    },
    columns() {
      const { columns } = this.rednderConfig;
      const isFunc = typeof columns === "function";
      const fixedColumns = isFunc ? columns(this) : columns;
      return [...this.dynamicColumns, ...fixedColumns];
    },
    headerData() {
      let { header } = this.rednderConfig;
      return {
        pic: header.pic, //url
        title: header.title, //标题
        desc: header.desc,
      };
    },
    paramsJSON() {
      return JSON.stringify(this.params);
    },
    tenantId() {
      return this.$store.getters.userInfo.tenantId || null;
    },
    tenantType() {
      return this.$store.getters.userInfo.tenantType || "0";
    },
    ...mapGetters({
      dataList: "dataList",
      userInfo: "userInfo",
    }),
  },
  watch: {
    "rednderConfig.filterArr": {
      immediate: true,
      deep: true,
      handler(val) {
        val.forEach((item) => {
          item.filterList.map((el) => {
            this.getDicTionary(el.key);
            this.$set(this.params, el.key, el.default || null);
          });
        });

        this.paramsBak = { ...this.params };
      },
    },
    paramsJSON: {
      deep: true,
      handler(val, old) {
        let newObj = JSON.parse(val);
        let oldObj = JSON.parse(old);
        ["firstCategory"].forEach((key) => {
          this.dealLink(newObj, oldObj, key);
        });
      },
    },
  },
  created() {
    this.initDateRange();
    this.getTableData(1);
    this.getStatisticTotal();
  },
  mounted() {},
  methods: {
    handleFilters(arr) {
      if (this.userInfo?.needPeekIsolation) return arr;
      // 当前账户无子机构过滤掉机构查询条件
      const result = arr.filter((item) => item.key !== "orgCode");
      return result;
    },
    initDateRange() {
      if (
        ["meterRate", "valveOperate", "deviceDevelop", "alarmDeal"].includes(
          this.type
        )
      ) {
        const startDate = this.dayjs()
          .subtract(
            ["deviceDevelop", "alarmDeal"].includes(this.type) ? 30 : 7,
            "day"
          )
          .format("YYYY-MM-DD HH:mm:ss");

        const endDate = this.dayjs().format("YYYY-MM-DD HH:mm:ss");
        this.$set(this.params, "dateSection", [startDate, endDate]);
      }
    },
    dealLink(val, old, key) {
      const linkMap = {
        firstCategory: "secondCategory",
      };
      if (val[key] === null) {
        this.resetLinked(linkMap[key]);
      } else if (val[key] !== old[key]) {
        this.getLinked(val[key], linkMap[key]);
      }
    },
    resetLinked(key) {
      this.searchOption[key] = [];
      this.params[key] = null;
    },
    getLinked(val, key) {
      this.params[key] = null;
      switch (key) {
        case "secondCategory":
          apiGetSecondCategoryList(val).then((res) => {
            let list = res.list || [];
            let val = list.map((item) => {
              return {
                value: item.productCategoryId,
                label: item.name,
              };
            });
            this.searchOption.secondCategory = val;
          });
          return;
      }
    },
    getSearchParams() {
      let obj = {
        tenantId: this.tenantId,
      };

      this.dynamicColumns = [];
      for (var key in this.params) {
        let val = this.params[key];
        if (val) {
          if (key === "dateSection") {
            obj.startDate = this.dayjs(val[0]).format("YYYY-MM-DD HH:mm:ss");
            obj.endDate = this.dayjs(val[1]).format("YYYY-MM-DD HH:mm:ss");
          } else {
            obj[key] = val;
          }
        }
        // 机构参数处理
        if (key === "orgCode" && val) {
          obj[key] = [val];
        }

        // 统计维度参数+表格展示字段处理
        if (key === "statisticsType" && obj[key]) {
          const tempArray =
            typeof obj[key] === "string" ? [obj[key]] : [...obj[key]];

          const options = statisDimension[this.type];
          const dynamicColumns = [];
          tempArray.map((item) => {
            const columnItem = options.find((o) => o.value === item);
            if (columnItem) {
              const { label, columnKey } = columnItem;
              dynamicColumns.push({ key: columnKey, name: label });
            }
          });
          this.dynamicColumns = dynamicColumns;
          obj[key] = tempArray.join(",");
        }
      }
      return obj;
    },
    getTableData(current) {
      this.loading = true;
      let obj = this.getSearchParams(current);
      obj.current = current;
      obj.size = 10;

      const apiMap = {
        deviceDevelop: apiGetDeviceDevelopList,
        alarmDeal: apiGetAlarmProcessList,
        factoryAlarm: apiGetDeviceAlarmList,
        meterRate: apiReportMeterReading,
        manyDaysNoUpload: apiReportNoUpload,
        valveOperate: apiReportValve,
      };
      apiMap[this.type](obj)
        .then((res) => {
          this.tableData = res.records || [];
          this.total = res.total;
          this.gasTotal = res.totalResult?.totalAmount;
          this.currentPage = res.current;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },

    async getStatisticTotal() {
      if (
        this.tenantType == 1 &&
        ["deviceDevelop", "alarmDeal"].includes(this.type)
      ) {
        const data = await statisticMap[this.type](this.getSearchParams());
        this.totalStatistic = data;
      }
    },

    getDicTionary(key) {
      const { eventSource, eventName } = this.dataList;
      let userTypeArr =
        JSON.parse(JSON.stringify(this.$store.getters.dataList.userType)) || [];
      switch (key) {
        case "firstCategory":
          apiGetFirstCategoryList().then((res) => {
            let list = res.list || [];
            let val = list.map((item) => {
              return {
                value: item.productCategoryId,
                label: item.name,
              };
            });
            this.$set(this.searchOption, "firstCategory", val);
          });
          return;
        case "secondCategory":
          this.$set(this.searchOption, "secondCategory", []);
          return;
        case "manufacturerId":
          apiGetManufacturerList({
            current: 1,
            size: 9999,
            tenantId: this.$store.getters.userInfo.tenantId || undefined,
          }).then((res) => {
            let list = res.records || [];
            let val = list.map((item) => {
              return {
                value: item.manufacturerId,
                label: item.manufacturerName,
              };
            });
            this.$set(this.searchOption, "manufacturerId", val);
          });
          return;
        case "deviceTypeId":
          apiGetDeviceTypeList(this.tenantId).then((res) => {
            let list = res.records || [];
            let val = list.map((item) => {
              return {
                value: item.deviceTypeId,
                label: item.deviceTypeName,
              };
            });
            this.$set(this.searchOption, "deviceTypeId", val);
          });
          return;
        case "alarmType":
          apiGetEventModelList().then((res) => {
            let list = res.list || [];
            let val = list.map((item) => {
              return {
                value: item.eventId,
                label: item.name,
              };
            });
            this.$set(this.searchOption, "alarmType", val);
          });
          return;
        case "eventSource": // 操作来源下拉列表
          this.$set(
            this.searchOption,
            "eventSource",
            transDataList(eventSource) || []
          );
          return;
        case "eventName": // 操作类型下拉列表
          this.$set(
            this.searchOption,
            "eventName",
            transDataList(eventName) || []
          );
          return;
        case "userType":
          if (this.type === "deviceDevelop" && this.tenantType == 1) {
            userTypeArr.push({
              defaultValue: "--",
              name: "待安装",
              sortCode: "userType",
              sortName: "客户大类",
              sortNo: 99,
              tenantType: "1",
            });
          }
          this.$set(this.searchOption, "userType", transDataList(userTypeArr));
          return;
      }
    },
    pageChange(val) {
      this.getTableData(val);
    },
    query() {
      this.$refs.reportForm.validate((valid) => {
        if (valid) {
          this.getTableData(1);
        }
      });
      this.getStatisticTotal();
    },
    reset() {
      this.params = { ...this.paramsBak };
      this.initDateRange();
      this.$refs.reportForm.clearValidate();

      this.getTableData(1);
      this.getStatisticTotal();
    },
    // 导出报表
    exportTable() {
      if (!this.tableData.length) {
        this.$message.warning("暂无表格数据");
        return;
      }
      this.$toast("下载中，请稍后...");
      let obj = this.getSearchParams();
      let myObj = {
        method: "post",
        url: exportMaps[this.type].url,
        fileName: exportMaps[this.type].fileName,
        data: obj,
      };
      exportReport(myObj, "excel", "", this.$toast);
    },
  },
};
</script>
<style lang="scss" scoped>
.common-shell {
  @include base-button(150px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  &-content {
    flex: 1;
    display: flex;
    height: 0;
    .left-filter {
      width: 366px;
      height: 100%;
      padding: 18px 20px 12px;
      border-right: 1px solid #f1f1f1;
      box-sizing: border-box;
      overflow: auto;
      .filter-modules {
        padding-bottom: 10px;
        .title {
          font-size: 17px;
          font-weight: 600;
          color: #333333;
        }
        .item {
          margin: 20px 0;
        }
      }
      .button-zone {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .right-table {
      flex: 1;
      width: 0;
      padding: 18px 20px 0;
      .table {
        height: 100%;
        display: flex;
        flex-direction: column;
        .water-statistic {
          margin-bottom: 12px;
          display: flex;
          .t {
            margin-right: 20px;
          }
          .statistic-item {
            margin-right: 24px;
            display: flex;
            align-items: center;
            p {
              color: #999;
              margin-right: 6px;
            }
          }
        }
        .table-content {
          flex: 1;
          height: 0;
        }
      }
    }
  }
}
::v-deep {
  .el-form-item--small .el-form-item__content {
    line-height: 0;
  }
  .gc-custom-search {
    width: initial !important;
  }
}
</style>
