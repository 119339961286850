<template>
  <!-- 自定义表格组件 -->
  <div class="gc-table" v-on="$listeners">
    <div
      v-if="(!columns.length || !tableData.length) && totalHide"
      class="gc-table-box"
    >
      <gc-empty></gc-empty>
    </div>
    <div
      class="table-show-box"
      :class="{
        'is-show-page': showPage,
        'show-table-border': border,
        'had-not-got-data': !tableDataCopy.length || !tableDataCopy,
      }"
      v-if="(columns.length && tableData.length) || !totalHide"
    >
      <el-table
        ref="gcCloudTable"
        :data="tableDataCopy"
        :border="border"
        style="width: 100%"
        height="100%"
        :header-cell-style="{
          background: headerBackGroud,
          color: headerColor,
          'font-weight': 600,
        }"
        :highlight-current-row="isHighlightCurrent"
        :stripe="isStripe"
        :show-header="showHeader"
        :fit="fit"
        @selection-change="handleSelectionChange"
        @row-click="rowClick"
        size="medium"
      >
        <template slot="empty" class="noData">
          <gc-empty :page-type="pageType"></gc-empty>
        </template>
        <el-table-column
          v-if="needType"
          width="55"
          :type="needType"
          :align="headerTextAlign || 'left'"
          :selectable="selectable"
        >
        </el-table-column>
        <template v-for="(item, index) in columns">
          <el-table-column
            v-if="!item.hide"
            :ref="item.key"
            :key="index"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :width="item.width"
            :resizable="!item.noResize"
            :min-width="item.minWidth || 150"
            :align="item.align || 'left'"
            :render-header="
              item.isRH
                ? renderHeader
                : (h, { column }) => h('span', column.label)
            "
            :show-overflow-tooltip="item.tooltip"
          >
            <template slot-scope="scope">
              <customer-render
                v-if="item.render"
                :row="scope.row"
                :render="item.render"
              ></customer-render>
              <span v-else>{{
                judgeBlank(scope.row[item.key])
                  ? item.emptyCellText || emptyCellText + ""
                  : scope.row[item.key]
              }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div v-if="showPagination" class="pagination-box">
      <gc-pagination
        ref="pagination"
        :total="total"
        :type="paginationType"
        :page-size="pageSize"
        :gas-total="gasTotal"
        :current-page.sync="currentPageSelf"
        :page-params="pageParams"
        @current-page-change="currentPageChange"
        @current-size-change="currentSizeChange"
      ></gc-pagination>
    </div>
  </div>
</template>

<script>
import GcPagination from "../GcPagination";
import GcEmpty from "../GcEmpty";
import { isBlank } from "@/utils/validate.js";
export default {
  name: "GcTable",
  components: {
    GcEmpty,
    GcPagination,
    customerRender: {
      name: "customerRender",
      props: {
        row: {
          type: Object,
          default: () => {},
        },
        render: {
          type: Function,
          default: function () {},
        },
      },
      render: function (h) {
        return this.render(h, this.row);
      },
    },
  },
  props: {
    // 自定义表头
    /**
     * columns[0]={
     * key 表格表头对应字段
     * name 表格表头文本
     * fixed 表格表头固定位置
     * minWidth 表格最小宽度
     * align 表格内容对齐方式
     * isRH 表格是否自定义筛选表头展示
     * tooltip 文本过长显示省略号
     * emptyCellText 字段为空时展示的内容，要求字符串格式，避免0不被识别
     * pageType 空白页配置类型
     * */
    pageType: {
      type: String,
      default: "default",
    },
    paginationType: {
      type: String,
      default: "normal",
    },
    pageParams: {
      type: Object,
      default: () => {
        return {
          currentPage: 1,
          hasUp: false,
          hasNext: true,
        };
      },
    },
    columns: {
      type: Array,
      default: () => [],
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    // 是否展示分页器
    showPage: {
      type: Boolean,
      default: false,
    },
    // 是否全部隐藏表格
    totalHide: {
      type: Boolean,
      default: true,
    },
    // 显示分页器的话需要传入列表数据总数
    total: {
      type: Number,
      default: 0,
    },
    // 显示分页器传入列表数据页宽，不传默认一页十条
    pageSize: {
      type: Number,
      default: 10,
    },
    //  当前页码
    currentPage: {
      type: [Number, String],
      default: 0,
    },
    // 表格数据查询条件
    searchData: {
      type: Object,
      default: () => {},
    },
    // 表头行的背景色
    headerBackGroud: {
      type: String,
      default: "#EBEDFA",
    },
    // 表头行的字体色
    headerColor: {
      type: String,
      default: "#666A80",
    },
    // 是否展示表格type列, selection-多选框；index-从1开始的序号；expand-显示为一个可展开的按钮
    needType: {
      type: String,
      default: "",
    },
    // 表格表头文本的对齐方式
    headerTextAlign: {
      type: String,
      default: "",
    },
    // 是否要高亮当前行
    isHighlightCurrent: {
      type: Boolean,
      default: false,
    },
    // 是否显示斑马行
    isStripe: {
      type: Boolean,
      default: false,
    },
    // 是否显示表头
    showHeader: {
      type: Boolean,
      default: true,
    },
    // 是否显示侧边框
    border: {
      type: Boolean,
      default: false,
    },
    // 列的宽度是否自撑开
    fit: {
      type: Boolean,
      default: true,
    },
    // 是否对表头过滤下拉菜单的下拉项进行过滤
    filterDropList: {
      type: Boolean,
      default: false,
    },
    // 表头筛选的下拉list的map合集
    filterMap: {
      type: Object,
      default: () => {},
    },
    // 字段数据为空数据时显示的文本内容
    emptyCellText: {
      type: String,
      default: "--",
    },
    // 用气报表总用气量
    gasTotal: {
      type: Number,
      default: 0,
    },
    //
    selectable: {
      type: Function,
      default: (row, index) => true,
    },
  },
  data() {
    return {
      tableDataCopy: [],
      commandMap: {},
      defaultMaxHeight: 900,
      specialCurrentPage: "",
    };
  },
  computed: {
    showPagination() {
      let status = false;
      if (this.showPage && this.tableData.length) {
        status = true;
      }
      if (
        this.showPage &&
        this.paginationType === "special" &&
        !isBlank(this.currentPage)
      ) {
        status = true;
      }
      return status;
    },
    currentPageSelf: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("update:currentPage", val);
      },
    },
  },
  watch: {
    tableData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.tableDataCopy = JSON.parse(JSON.stringify(val));
      },
    },

    columns: {
      handler() {
        this.$nextTick(() => {
          this.$refs.gcCloudTable?.doLayout();
        });
      },
      deep: true,
    },
  },
  methods: {
    // 展示字段判空显示
    judgeBlank(val) {
      return isBlank(val);
    },
    // 自定义渲染表头
    renderHeader(h, params) {
      // 获取当前列的信息column与table的全部渲染数据tableData
      let { column } = params;
      let key = column.property;
      let elDrownItemMap = [["全部", key]];
      // 遍历出来需要筛选的字段值列表
      if (this.filterDropList) {
        this.tableDataCopy.reduce((pre, cur) => {
          // 去掉重复的筛选项
          if (cur[key] && pre.every((item) => item[0] !== cur[key])) {
            elDrownItemMap.push([cur[key], key]);
          }
          return elDrownItemMap;
        }, []);
      } else {
        this.filterMap[key].reduce((pre, cur) => {
          // 去掉重复的筛选项
          if (cur["label"] && pre.every((item) => item[0] !== cur["label"])) {
            elDrownItemMap.push([cur["label"], cur["value"]]);
          }
          return elDrownItemMap;
        }, []);
      }
      // 制作下拉项的render函数列表
      let elDrownItemList = elDrownItemMap.map((item) => {
        return h("el-dropdown-item", { props: { command: item } }, item[0]);
      });
      return h(
        "el-dropdown",
        {
          on: {
            command: this.handleCommand, // 下拉菜单某一项点击时触发
            "visible-change": this.visibleChange(key), // 下拉菜单显示与隐藏状态变化时触发
          },
          props: { trigger: "click", placement: "bottom-start" }, //点击触发筛选下拉
          ref: "elDrown",
        },
        [
          h("span", { class: "el-dropdown-link" }, [
            h("span", { style: `color:${this.headerColor}` }, column.label),
            h("i", {
              class: `el-icon-caret-top el-icon--right ${
                "el-custom-th-" + key
              }`,
              style: `color:${this.headerColor};margin-left:3px;`,
            }),
          ]),
          h("el-dropdown-menu", { slot: "dropdown" }, elDrownItemList),
        ]
      );
    },
    // 自定义筛选表头·下拉菜单·点击事件
    handleCommand(command) {
      this.commandMap[command[1]] = command[0];
      this.$emit("click-header-filter", command);
      if (this.filterDropList) {
        this.filterTableData(this.commandMap);
      }
    },
    // 根据自定义筛选表头·下拉菜单的点击事件进行表格数据的筛选
    filterTableData(map) {
      this.tableDataCopy = this.tableData;
      for (var key in map) {
        if (map[key] !== "全部") {
          this.tableDataCopy = this.tableDataCopy.filter(
            (item) => item[key] === map[key]
          );
        }
      }
    },
    // 自定义筛选表头·下拉窗口·显示与隐藏状态·变化时出发的事件
    visibleChange(key) {
      return (params) => {
        let dom = document.querySelector(".el-custom-th-" + key);
        if (params) {
          dom.classList.add("el-icon-caret-top-rotate");
        } else {
          dom.classList.remove("el-icon-caret-top-rotate");
        }
      };
    },
    // 表格选项变化
    handleSelectionChange(selections) {
      this.$emit("selectChange", selections);
    },
    // 清空多选表格选项,rows可以为数组或者单一行数据，也可以不传，不传的时候表格选中项全部清空
    clearCheckTableSelection(rows) {
      if (Array.isArray(rows)) {
        rows.forEach((row) => {
          const t = this.tableDataCopy.find((o) => o.id === row.id);
          t && this.$refs.gcCloudTable.toggleRowSelection(t);
        });
      } else if (rows) {
        const t = this.tableDataCopy.find((o) => o.id === rows.id);
        t && this.$refs.gcCloudTable.toggleRowSelection(t);
      } else {
        this.$refs.gcCloudTable.clearSelection();
      }
    },

    setCurrentRow(row) {
      const t = row ? this.tableDataCopy.find((o) => o.id === row.id) : null;
      this.$refs.gcCloudTable.setCurrentRow(t);
    },
    // 表格行点击事件,会把点击的行信息与列的key信息传递出去，用于使用者辨别当前列点击是否需要阻断行点击事件
    rowClick(row, column, event) {
      this.$emit("row-click", { row, key: column.property, event });
    },
    // 当前页码变化
    currentPageChange(page) {
      this.$emit("current-page-change", page);
    },
    // 当前页宽变化
    currentSizeChange(size) {
      this.$emit("current-size-change", size);
    },
  },
};
</script>
<style lang="scss" scoped>
.gc-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  .table-show-box,
  .gc-table-box {
    height: 100%;
  }
  .is-show-page {
    height: calc(100% - 54px);
  }
  ::v-deep .el-icon-caret-top {
    transition: All 0.4s ease-in-out;
    -webkit-transition: All 0.4s ease-in-out;
    -moz-transition: All 0.4s ease-in-out;
    -o-transition: All 0.4s ease-in-out;
  }
  ::v-deep .el-icon-caret-top-rotate {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
  ::v-deep .el-dropdown-link {
    cursor: pointer;
    color: #333;
    // &:hover {
    // }
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #fff;
    border-color: #409eff;
  }
  ::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner {
    background-color: #f7f9fc;
    border-color: #dcdfe6;
  }
  ::v-deep .el-checkbox__inner {
    width: 16px;
    height: 16px;
    &::after {
      border: 2px solid #409eff;
      border-left: 0;
      border-top: 0;
      left: 4px;
      top: 1px;
      width: 4px;
    }
  }

  ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #fff;
    border-color: #409eff;
  }
  ::v-deep
    .el-checkbox__input.is-indeterminate.is-disabled
    .el-checkbox__inner {
    background-color: #f7f9fc;
    border-color: #dcdfe6;
    &::before {
      background-color: #dcdfe6;
    }
  }
  ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
    background-color: #409eff;
    top: 5px;
    height: 4px;
  }
  // 隐藏表头里面的全选框
  // ::v-deep .el-table__header-wrapper .el-checkbox {
  //   display: none;
  // }
  ::v-deep .el-table th.gutter {
    /*
      * 解决element-ui 表格篡位的问题
      */
    display: table-cell !important;
    border: 1px solid transparent;
  }
  ::v-deep .el-table--border {
    border: none;
  }
  ::v-deep .show-table-border {
    .el-table tr {
      th:nth-child(1) {
        border-left: 1px solid $base-color-border;
      }
      td:nth-child(1) {
        border-left: 1px solid $base-color-border;
      }
      th:last-child {
        border-right: 2px solid $base-color-border;
        border-radius: 0 8px 0 0;
      }
      td:last-child {
        border-right: 2px solid $base-color-border;
      }
    }
    .el-table td {
      border-right: 1px solid $base-color-border;
    }
    .el-table th {
      border-top: 1px solid $base-color-border;
      border-right: 1px solid $base-color-border;
      &.gutter {
        border: 1px solid transparent !important;
      }
    }
    .el-table.el-table--scrollable-y {
      .el-table__body-wrapper.is-scrolling-none {
        padding-right: 2px;
        tr {
          th:last-child {
            border-right: 1px solid $base-color-border;
          }
          td:last-child {
            border-right: 1px solid $base-color-border;
          }
        }
      }
    }
  }
  ::v-deep .el-table__body-wrapper {
    // padding-right: 2px;
  }
  ::v-deep .el-table {
    color: #4e4e4e;
    & .cell,
    & th {
      &:nth-child(1) {
        border-radius: 8px 0 0 0;
      }
      &:nth-last-child(2) {
        border-radius: 0 8px 0 0;
      }
      // & > .cell {
      //   padding-left: 42px;
      // }
    }
    &::before {
      height: 0;
    }
  }
  ::v-deep.el-table__empty-block {
    width: 100% !important;
  }
  .had-not-got-data {
    ::v-deep .el-table__fixed-right {
      box-shadow: none;
    }
  }
  ::v-deep .el-table__fixed-right {
    &::before {
      background-color: transparent;
    }
  }
  ::v-deep .el-table__empty-block {
    align-items: flex-start;
  }
}
</style>
