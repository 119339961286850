const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  permissions: (state) => state.user.permissions,
  isolationOrgId: (state) => state.user.isolationOrgId,
  isolationOrgCode: (state) => state.user.isolationOrgCode,
  isolationOrgName: (state) => state.user.isolationOrgName,
  showSwitchOrgDialogGlobal: (state) => state.user.showSwitchOrgDialogGlobal,
  routes: (state) => state.app.routes,
  dataList: (state) => state.common.dataList,
  needRefresh: (state) => state.account.needRefresh,
  provinceList: (state) => state.apiCache.provinceList,
  treeOptions: (state) => state.apiCache.treeOptions,
  userRegionTree: (state) => state.user.userRegionTree,
  isSysUser: (state) => {
    const { userLevel, isTenantAdmin } = state.user.userInfo;
    return userLevel === 0 || isTenantAdmin; // 是否为系统管理员或租户管理员
  },
  pressureUnitName: (state) =>
    state.user.userInfo.tenantType == 1 ? "Mpa" : "Kpa",
};
export default getters;
